import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicTable } from '../shared/models/table';
import { map } from 'rxjs/operators';
import { DocumentationFile } from './models/documentation-item';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  private apiUrl = 'api/documentation';
  constructor(private http: HttpClient) {}

  getDocumentation(parameters: string): Observable<DynamicTable> {
    if (parameters) {
      parameters = '?' + parameters;
    }
    return this.http.get(this.apiUrl + parameters).pipe(map((res) => res as DynamicTable));
  }

  getDocumentationFiles(id: number): Observable<DocumentationFile[]> {
    return this.http.get(this.apiUrl + '/' + id + '/files').pipe(map((res) => res as DocumentationFile[]));
  }
}
