import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceLimitItem } from '../../models/service-limit-item';
import { DynamicTable } from '../../../shared/models/table';

@Component({
  selector: 'app-service-view',
  templateUrl: './service-view.component.html',
  styleUrls: ['./service-view.component.scss'],
})
export class ServiceViewComponent implements OnInit {
  serviceLimitTable: DynamicTable;
  serviceTable: DynamicTable;
  isDataLoaded = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.serviceLimitTable = this.route.snapshot.data['serviceLimits'];
    this.serviceTable = this.route.snapshot.data['services'];
    this.isDataLoaded = true;
  }
}
