import { Injectable } from '@angular/core';
import { User } from './models/user';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string) {
    return this.http.post('/api/authenticateCustomer', { username: username, password: password }).pipe(
      map((res) => {
        if (res) {
          localStorage.setItem('currentUser', JSON.stringify(res));
        }
        return true;
      }),
    );
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http
      .post('/api/auth/customerChangePassword', { oldPassword: oldPassword, newPassword: newPassword })
      .pipe(
        map((res) => {
          if (res) {
            // localStorage.setItem('currentUser', JSON.stringify(res.response));
          }
          return true;
        }),
      );
  }

  resetPasswordAttempt(loginEmail: string) {
    return this.http.get('/api/auth/customerResetPasswordAttempt?login=' + loginEmail);
  }

  resetPassword(guid: string, newPassword: string) {
    return this.http.post('/api/auth/customerResetPassword', { guid: guid, newPassword: newPassword });
  }

  isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser() {
    if (localStorage.getItem('currentUser')) {
      return JSON.parse(localStorage.getItem('currentUser')) as User;
    } else {
      return null;
    }
  }
}
