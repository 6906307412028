import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DynamicTable } from '../shared/models/table';
import { CasesService } from './cases.service';
import { CaseComment, CaseItem } from './models/case';

@Injectable()
export class CasesResolve implements Resolve<DynamicTable> {
  constructor(private casesService: CasesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.casesService.getCases('');
  }
}

@Injectable()
export class CaseByIdResolve implements Resolve<CaseItem> {
  constructor(private casesService: CasesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.casesService.getCaseById(route.params['id']);
  }
}

@Injectable()
export class CaseByIdCommentsResolve implements Resolve<CaseComment[]> {
  constructor(private casesService: CasesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.casesService.getCaseByIdComments(route.params['id']);
  }
}
