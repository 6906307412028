import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '../models/list-item';

@Pipe({
  name: 'listText',
})
export class ListTextPipe implements PipeTransform {
  transform(value: Array<ListItem>, id: any): any {
    const result = value.find((item) => item.value === String(id));
    if (result !== undefined) {
      return result.label;
    } else {
      return '';
    }
  }
}
