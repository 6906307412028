import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactItem } from '../../models/contact-item';
import { PasswordChangeItem } from '../../../auth/models/password';
import { AdministrationService } from '../../administration.service';
import { AuthService } from '../../../auth/auth.service';
import { ToolsService } from '../../../shared/services/tools.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { forkJoin } from 'rxjs';
import { MustMatch } from '../../../shared/validators/mustMatch';
import { User } from '../../../auth/models/user';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
})
export class ContactEditComponent implements OnInit {
  contactForm: UntypedFormGroup;
  contactItem: ContactItem;
  passwordForm: UntypedFormGroup;
  passwordItem: PasswordChangeItem;
  isDataLoaded = false;
  showChangePassword = false;
  @ViewChild('editPasswordModal') passwordModal: any;
  @Input() refCustomerId = 0;
  @Input() refContactId = 0;
  @Output() returnClicked = new EventEmitter<any>();
  @Output() submitClicked = new EventEmitter<any>();
  private wasCPAccess = false;
  user: User;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private administrationService: AdministrationService,
    private authService: AuthService,
    private toolsService: ToolsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    if (!this.refContactId) {
      this.refContactId = this.route.snapshot.params.id;
    }
    forkJoin([this.administrationService.getContactById(this.refContactId)]).subscribe((results) => {
      this.contactItem = results[0];
      this.contactForm = this.buildContactForm();
      this.passwordForm = this.buildPasswordForm();
      this.showChangePassword = this.contactItem.cpAccess;
      this.wasCPAccess = this.contactItem.cpAccess;
      this.isDataLoaded = true;
      this.onChangesContactForm();
    });
  }

  buildContactForm() {
    return this.formBuilder.group({
      caseNotifications: this.formBuilder.group({
        added: [this.contactItem.caseNotifications.added],
        assigned: [this.contactItem.caseNotifications.assigned],
        closed: [this.contactItem.caseNotifications.closed],
        closingProposal: [this.contactItem.caseNotifications.closingProposal],
        autoClose: [this.contactItem.caseNotifications.autoClose],
        commentAdded: [this.contactItem.caseNotifications.commentAdded],
        ownAdded: [this.contactItem.caseNotifications.ownAdded],
        ownAssigned: [this.contactItem.caseNotifications.ownAssigned],
        ownClosed: [this.contactItem.caseNotifications.ownClosed],
        ownClosingProposal: [this.contactItem.caseNotifications.ownClosingProposal],
        ownAutoClose: [this.contactItem.caseNotifications.ownAutoClose],
        ownCommentAdded: [this.contactItem.caseNotifications.ownCommentAdded],
      }),
      email: [this.contactItem.email],
      email2: [this.contactItem.email2],
      firstName: [this.contactItem.firstName, Validators.required],
      lastName: [this.contactItem.lastName, Validators.required],
      phone: [this.contactItem.phone],
      phone2: [this.contactItem.phone2],
      jobTitle: [this.contactItem.jobTitle],
      forNotification: [this.contactItem.forNotification],
      salutation: [this.contactItem.salutation],
      active: [this.contactItem.active],
      cpAccess: [this.contactItem.cpAccess],
      cpCaseAccess: [this.contactItem.cpCaseAccess],
      cpUpdateAccess: [this.contactItem.cpUpdateAccess],
      cpAdmin: [this.contactItem.cpAdmin],
      cpReportAccess: [this.contactItem.cpReportAccess],
      cpServiceAccess: [this.contactItem.cpServiceAccess],
      toNews: [this.contactItem.toNews],
      toOffers: [this.contactItem.toOffers],
      toUpdates: [this.contactItem.toUpdates],
      forMienie: [this.contactItem.forMienie],
      forMapa: [this.contactItem.forMapa],
      forOsrodek: [this.contactItem.forOsrodek],
    });
  }

  buildPasswordForm() {
    return this.formBuilder.group(
      {
        newPassword: ['', Validators.required],
        newPassword2: ['', Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'newPassword2'),
      },
    );
  }

  submit() {
    if (this.contactForm.valid) {
      this.contactItem = Object.assign({}, this.contactForm.value);
      this.contactItem.id = this.refContactId;
      this.administrationService.updateContact(this.contactItem).subscribe(
        () => {
          this.administrationService.getContactById(this.refContactId).subscribe((data) => {
            this.contactItem = data;
            this.contactForm = this.buildContactForm();
            this.passwordForm = this.buildPasswordForm();
            this.showChangePassword = this.contactItem.cpAccess;
            this.wasCPAccess = this.contactItem.cpAccess;
          });
          this.toastService.info('Dane użytkownika zostały zapisane.', 'Potwierdzenie');
        },
        (ex) => {
          if (ex.status !== 412) {
            this.toastService.error('Wystąpił bład podczas zapisu danych użytkownika.', 'Błąd');
          } else {
            this.toastService.error(ex.error.message, 'Błąd');
          }
        },
      );
    } else {
      this.toolsService.validateAllFormFields(this.contactForm);
    }
  }

  submitPassword() {
    this.passwordItem = {} as PasswordChangeItem;
    this.passwordItem.newPassword = this.passwordForm.controls['newPassword'].value;
    this.passwordItem.userId = this.contactItem.id;
    this.administrationService.setPassword(this.passwordItem).subscribe(
      () => {
        this.toastService.info('Hasło zostało zmienione.', 'Potwierdzenie');
        this.passwordModal.hide();
      },
      () => {
        this.toastService.error('Podczas próby zmiany hasła wystąpił błąd.', 'Błąd');
      },
    );
  }

  resetPassword() {
    this.toolsService
      .showConfirmation(
        'Resetowanie hasła',
        'Zostanie wysłany email do użytkownika z informacją o metodzie' + ' ustawienia nowego hasła. Kontynuować?',
      )
      .subscribe((result: any) => {
        if (result) {
          this.administrationService.resetPassword(this.contactItem.id).subscribe(
            () => {
              this.toastService.info('Resetowanie hasła zostało przygotowane.', 'Potwierdzenie');
            },
            () => {
              this.toastService.error('Podczas próby resetu hasła wystąpił błąd.', 'Błąd');
            },
          );
        }
      });
  }

  invitteClick() {
    this.toolsService
      .showConfirmation(
        'Zaproszenie użytkownika',
        'Zostanie wysłany email do użytkownika z informacją o ' + 'założeniu kontw w Panelu Klienta. Kontynuować?',
      )
      .subscribe((result: any) => {
        if (result) {
          this.administrationService.invite(this.contactItem.id).subscribe(
            () => {
              this.toastService.info('Zaproszenie zostało przygotowane.', 'Potwierdzenie');
            },
            () => {
              this.toastService.error('Podczas próby wysłania zaproszenia wystąpił błąd.', 'Błąd');
            },
          );
        }
      });
  }

  onChangesContactForm(): void {
    this.contactForm.get('cpAccess').valueChanges.subscribe(() => {
      this.showChangePassword = this.wasCPAccess && this.contactForm.controls['cpAccess'].value;
    });
  }

  return() {
    if (this.refCustomerId !== 0) {
      this.returnClicked.emit();
    } else {
      this.router.navigate(['administracja/uzytkownicy']);
    }
  }
}
