<!--<div class="view form-header p-2 mt-0 mb-0 pt-3  align-items-center">-->
<!--  <h5 class="font-bold">Profil kolorystyczny</h5>-->
<!--  <div></div>-->
<!--</div>-->

<div class="row">
  <div class="col-lg-3">
    <div class="card-body h-100">
      <h5 class="mt-2 pb-2">Wybrany schemat</h5>

      <!--<div class="row">-->
      <!--  <div class="col-6">-->
      <!--    <br>-->
      <div class="form-check" (click)="changeSkin('white-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio1" />
        <label for="radio1" class="form-check-label">Biały</label>
      </div>
      <div class="form-check" (click)="changeSkin('black-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio2" />
        <label for="radio2" class="form-check-label">Czarny</label>
      </div>
      <div class="form-check" (click)="changeSkin('cyan-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio3" />
        <label for="radio3" class="form-check-label">Żółty</label>
      </div>
      <div class="form-check" (click)="changeSkin('mdb-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio5" />
        <label for="radio5" class="form-check-label">Niebieski</label>
      </div>
      <div class="form-check" (click)="changeSkin('deep-purple-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio6" />
        <label for="radio6" class="form-check-label">Purpurowy</label>
      </div>
      <div class="form-check" (click)="changeSkin('navy-blue-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio7" />
        <label for="radio7" class="form-check-label">Cyjan</label>
      </div>
      <div class="form-check" (click)="changeSkin('pink-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio8" />
        <label for="radio8" class="form-check-label">Indygo</label>
      </div>
      <div class="form-check" (click)="changeSkin('indigo-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio9" />
        <label for="radio9" class="form-check-label">Różowy</label>
      </div>
      <div class="form-check" (click)="changeSkin('light-blue-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio10" />
        <label for="radio10" class="form-check-label">Szary</label>
      </div>
      <div class="form-check" (click)="changeSkin('grey-skin')">
        <input mdbInput mdbValidate class="form-check-input" name="group1" type="radio" id="radio11" />
        <label for="radio11" class="form-check-label">Jasnoniebieski</label>
      </div>
    </div>
  </div>

  <div class="col-lg-9">
    <div class="card-body h-100">
      <h5 class="mt-2 pb-2">Przykład widoku</h5>
      <div class="row">
        <div class="col-lg-7">
          <!--    <div class="card-body h-100">-->

          <!--  <div class="col-6">-->
          <!--    <br>-->
          <!--    <br>-->
          <!--    <br>-->
          <div class="mt-5">
            <div class="form-header">
              <h5>Nagłówek</h5>
            </div>
            <div class="md-form">
              <!--        <i class="fa fa-user prefix"></i>-->
              <input
                class="form-control"
                id="flogin"
                type="text"
                data-error="wrong text"
                data-success="right text"
                mdbActive
              />
              <label class for="flogin">Przykładowe pole1</label>
            </div>
            <div class="md-form">
              <!--        <i class="fa fa-lock prefix"></i>-->
              <input class="form-control" id="fpass" type="text" mdbActive />
              <label class for="fpass">Przykładowe pole 2</label>
            </div>
            <!--          <div class="text-center">-->
            <!--            <button class="btn btn-default btn-sm waves-light" mdbRippleRadius>Przycisk 1</button>-->
            <!--            <button class="btn btn-outline-default btn-sm waves-light" mdbRippleRadius>Przycisk 2</button>-->
            <!--            <button class="btn btn-primary btn-sm waves-light" mdbRippleRadius>Przycisk 3</button>-->
            <!--            <button class="btn btn-secondary btn-sm waves-light" mdbRippleRadius>Przycisk 4</button>-->

            <!--          </div>-->
            <!--    </div>-->
          </div>
        </div>

        <div class="col-lg-5">
          <div class="row">
            <div class="col">
              <div class="text-center">
                <button class="btn btn-default btn-sm waves-light" mdbRippleRadius>Przycisk 1</button>
                <button class="btn btn-outline-default btn-sm waves-light" mdbRippleRadius>Przycisk 2</button>
                <button class="btn btn-primary btn-sm waves-light" mdbRippleRadius>Przycisk 3</button>
                <button class="btn btn-secondary btn-sm waves-light" mdbRippleRadius>Przycisk 4</button>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="text-center">
                <mdb-checkbox [checked]="true" [inline]="true">Pole wyboru 1</mdb-checkbox>
                <mdb-checkbox [checked]="false" [inline]="true">Pole wyboru 2</mdb-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
