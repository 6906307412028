import { Component, Input, OnInit } from '@angular/core';
import { SoftwareMainVersionItem } from '../../models/software-main-version-item';
import { UpdatesService } from '../../updates.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-update-show',
  templateUrl: './update-show.component.html',
})
export class UpdateShowComponent implements OnInit {
  @Input() refCustomerId: number;
  softwareMainVersionItem: SoftwareMainVersionItem;
  isDataLoaded = false;

  constructor(private updatesService: UpdatesService) {}

  ngOnInit(): void {
    forkJoin([this.updatesService.getSoftwareMainVersion(false)]).subscribe((results) => {
      this.softwareMainVersionItem = results[0];
      this.isDataLoaded = true;
    });
  }

  refreshClick() {
    this.updatesService.getSoftwareMainVersion(true).subscribe((data) => {
      this.softwareMainVersionItem = data;
    });
  }
}
