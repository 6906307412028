import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AttachmentFile } from '../../../shared/models/file';
import { CommentAddItem } from '../../models/case';
import { AuthService } from '../../../auth/auth.service';
import { CasesService } from '../../cases.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-case-comment',
  templateUrl: './case-comment.component.html',
  styleUrls: ['./case-comment.component.scss'],
})
export class CaseCommentComponent implements OnInit {
  commentToAdd: CommentAddItem;
  attachmentList: AttachmentFile[];
  commentForm: UntypedFormGroup;
  @Input() caseId: number;
  @Input() receiverEmail = '';
  @Input() receiver = '';
  @Input() title = '';
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  inProgress = false;

  constructor(
    private authService: AuthService,
    private casesService: CasesService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.commentForm = this.buildCommentForm();
    this.attachmentList = [];
  }

  buildCommentForm() {
    const currentUser = this.authService.getCurrentUser();
    return this.formBuilder.group({
      comment: ['', Validators.required],
      type: ['0'],
      title: [this.title],
      receiver: [this.receiver],
      receiverEmail: [this.receiverEmail],
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    if (!this.inProgress) {
      this.inProgress = true;
      this.commentToAdd = Object.assign({}, this.commentForm.value);
      this.commentToAdd.attachmentList = this.attachmentList;
      this.casesService.addComment(this.caseId, this.commentToAdd).subscribe(
        (data) => {
          this.onSubmitSuccess();
        },
        (error) => {
          this.onSubmitFailure();
          // this.alertService.error(error);
          // this.loading = false;
          this.inProgress = false;
        },
      );
    }
  }

  private onSubmitSuccess() {
    this.save.emit();
  }

  private onSubmitFailure() {
    const options = { closeButton: true, tapToDismiss: false, positionClass: 'toast-bottom-right' };
    this.toastService.error('Wystąpił błąd podczas zapisywania komentarza', 'Błąd', options);
  }

  addToAttachment(file: AttachmentFile) {
    this.attachmentList.push(file);
  }

  removeAttachment(file: AttachmentFile): void {
    this.attachmentList = this.attachmentList.filter((el) => el !== file);
  }
}
