import { Component, Input, OnInit } from '@angular/core';
import { AdministrationService } from '../../administration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableSettingsService } from '../../../shared/services/table-settings.service';
import { AuthService } from '../../../auth/auth.service';
import { DynamicTable } from '../../../shared/models/table';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
})
export class ContactListComponent implements OnInit {
  contactsTable: DynamicTable;
  contactId = 0;
  contactViewStatus = 0;
  @Input() refCustomerId = 0;

  constructor(
    private administrationService: AdministrationService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private tableSettingsService: TableSettingsService,
  ) {}

  ngOnInit() {
    this.contactsTable = this.route.snapshot.data['contacts'];
  }

  reload(resetPage = false, firstRun = false) {
    let parameters = this.tableSettingsService.getRequestParams('CPContactsList');
    if (this.refCustomerId) {
      parameters += '&filter.ConCusId=' + this.refCustomerId;
    }
    this.administrationService.getContacts(parameters, firstRun).subscribe((data) => {
      this.contactsTable = data;
      if (firstRun) {
        this.tableSettingsService.setRecordsPerPage('CPContactsList', data.paging.recordsPerPage);
      }
    });
  }

  viewReload() {
    this.reload(true, true);
  }

  add() {
    this.router.navigate(['administracja/uzytkownicy/dodaj']);
  }

  edit(id) {
    this.router.navigate(['administracja/uzytkownicy/edycja', id]);
  }

  return(reload) {
    if (reload) {
      this.reload();
    }
    this.contactViewStatus = 0;
  }
}
