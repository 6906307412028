import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BreadcrumbItem } from '../models/breadcrumb-item';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  activeElement = 'panel';
  breadCrumbs: BreadcrumbItem[] = [];

  mainHeaderBar$ = new Subject<boolean>();

  showMainHeader() {
    this.mainHeaderBar$.next(true);
  }
  hideMainHeader() {
    this.mainHeaderBar$.next(false);
  }

  breadCrumbAdd(caption: string, param: string) {
    this.breadCrumbs.push({
      id: 0,
      caption: caption,
      params: param,
      type: 0,
    });
  }
  changeActiveElement(name: string) {
    this.activeElement = name;
  }
}
