import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaseAddItem } from '../../models/case';
import { AttachmentFile } from '../../../shared/models/file';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CasesService } from '../../cases.service';
import { AuthService } from '../../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheService } from '../../../shared/services/cache.service';
import { ListService } from '../../../shared/services/list.service';
import { ToolsService } from '../../../shared/services/tools.service';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { LoaderDialogComponent } from '../../../shared/modals/loader-dialog/loader-dialog.component';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-case-add',
  templateUrl: './case-add.component.html',
  styleUrls: ['./case-add.component.scss'],
})
export class CaseAddComponent implements OnInit {
  caseForm: UntypedFormGroup;
  typeSelect: Array<any>;
  attachmentList: AttachmentFile[];
  caseToAdd: CaseAddItem;
  isDataLoaded = false;
  productSelect: Array<any>;
  criticalitySelect: Array<any>;
  criticalitySelectFull: Array<any>;
  sourceSelect: Array<any>;
  @Input() refCustomerId = 0;
  @Output() returnClicked = new EventEmitter<any>();
  @Output() submitClicked = new EventEmitter<any>();
  inProgress = false;
  modalRef: MDBModalRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private casesService: CasesService,
    private authService: AuthService,
    private toastService: ToastService,
    private toolsService: ToolsService,
    private router: Router,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private listService: ListService,
    private modalService: MDBModalService,
  ) {}

  ngOnInit() {
    this.caseForm = this.buildCaseForm();
    this.cacheService.get('programCP', this.listService.getProgramCP()).subscribe((data) => {
      this.productSelect = data;
    });
    this.cacheService.get('caseType', this.listService.getListByCode('CaseType')).subscribe((data) => {
      this.typeSelect = data;
    });
    this.cacheService.get('caseCriticality', this.listService.getListByCode('CaseCriticality')).subscribe((data) => {
      this.criticalitySelectFull = data;
    });
    this.cacheService.get('caseSource', this.listService.getListByCode('CaseSource')).subscribe((data) => {
      this.sourceSelect = data;
    });

    this.attachmentList = [];
    this.isDataLoaded = true;
  }

  buildCaseForm() {
    const currentUser = this.authService.getCurrentUser();
    return this.formBuilder.group({
      title: ['', Validators.required],
      typeId: ['', Validators.required],
      productId: ['', Validators.required],
      criticalityId: ['', Validators.required],
      sourceId: [''],
      comment: ['', Validators.required],
      contactName: [currentUser.firstName + ' ' + currentUser.surname, Validators.required],
      contactPhone: [currentUser.phone, Validators.required],
      contactEmail: [currentUser.email],
      clientNumber: [''],
    });
  }

  clickSubmit() {
    this.caseToAdd = Object.assign({}, this.caseForm.value);
    if (this.criticalitySelectFull.find((x) => x.value === this.caseToAdd.criticalityId).code === '6') {
      this.toolsService
        .showConfirmation(
          'Ostrzeżenie',
          'Czy to zgłoszenie na pewno jest AWARIĄ? W przypadku negatywnej weryfikacji zgłoszenie zostanie zamknięte bez rozpatrzenia.',
          'danger',
        )
        .subscribe((result) => {
          if (result) {
            this.onSubmit();
          }
        });
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    // debugger
    // this.caseToAdd = Object.assign({}, this.caseForm.value);
    // if (this.criticalitySelectFull.find(x => x.value === this.caseToAdd.criticalityId).code === '6') {
    //   this.toolsService.showConfirmation('Ostrzeżenie', 'Czy to zgłoszenie na pewno jest AWARIĄ? W przypadku negatywnej weryfikacji zgłoszenie zostanie zamknięte bez rozpatrzenia.'
    //    , 'danger')
    //     .subscribe((result) => {
    //       if (result.status) {
    //       //  this.submitClicked.emit(this.caseToAdd);
    //       }
    //     });
    // }

    if (!this.inProgress) {
      this.inProgress = true;
      if (this.caseForm.valid) {
        this.caseToAdd = Object.assign({}, this.caseForm.value);
        this.caseToAdd.attachmentList = this.attachmentList;
        const modalOptions = {
          backdrop: true,
          keyboard: true,
          focus: true,
          show: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog modal-notify modal-info',
          animated: true,
          data: {
            ready: false,
            header: 'Operacja w toku',
            content: 'Zapisywanie zgłoszenia...',
          },
        };

        setTimeout(() => {
          if (this.inProgress) {
            this.modalRef = this.modalService.show(LoaderDialogComponent, modalOptions);
          }
        }, 500);
        this.casesService.addCase(this.caseToAdd).subscribe(
          (data) => {
            this.onSubmitSuccess();
            this.inProgress = false;
            if (this.modalRef) {
              this.modalRef.hide();
            }
          },
          (error) => {
            this.onSubmitFailure();
            this.inProgress = false;
            if (this.modalRef) {
              this.modalRef.hide();
            }
          },
        );
      } else {
        this.toolsService.validateAllFormFields(this.caseForm);
        this.inProgress = false;
      }
    }
  }

  private onSubmitSuccess() {
    this.returnClicked.emit(true);
  }

  private onSubmitFailure() {
    const options = {
      closeButton: true,
      tapToDismiss: true,
      progressBar: true,
      positionClass: 'md-toast-bottom-right',
    };
    this.toastService.error('Wystąpił bład podczas dodawania zgłoszenia.', 'Błąd', options);
  }

  getSelectedTypeValue(event: any) {
    this.criticalitySelect = this.criticalitySelectFull.filter((el) => el.parent === event);
  }

  addAttachment(file: AttachmentFile) {
    this.attachmentList.push(file);
  }

  removeAttachment(file: AttachmentFile): void {
    this.attachmentList = this.attachmentList.filter((el) => el !== file);
  }

  public Cancel() {
    this.returnClicked.emit();
  }

  return() {
    this.returnClicked.emit();
  }
}
