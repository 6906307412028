import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '../models/list-item';

@Pipe({
  name: 'listCode',
})
export class ListCodePipe implements PipeTransform {
  transform(value: Array<ListItem>, id: any): any {
    const result = value.find((item) => item.value === String(id));
    if (result !== undefined) {
      return result.code;
    } else {
      return '';
    }
  }
}
