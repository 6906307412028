import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DynamicTable } from '../shared/models/table';
import { AdministrationService } from './administration.service';

@Injectable()
export class ContactsResolve implements Resolve<DynamicTable> {
  constructor(private administrationService: AdministrationService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.administrationService.getContacts();
  }
}
