import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';

import { NgModule } from '@angular/core';
import { SkinComponent } from './components/skin/skin.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SettingsComponent } from './components/settings/settings.component';

const SETTINGS: Route[] = [
  {
    path: 'ustawienia',
    canActivate: [AuthGuard],
    component: <any>SettingsComponent,
    // children: [
    //   {
    //     path: 'widok',
    //     component: <any>SkinComponent,
    //   },
    //   {
    //     path: 'powiadomienia',
    //     component: <any>NotificationsComponent,
    //   }
    // ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(SETTINGS)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
