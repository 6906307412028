<div class="row mt-4">
  <div class="col-12 mt-3">
    <div class="card card-cascade narrower">
      <div class="view form-header py-2 mb-3 align-items-center">
        <h4 class="h4-responsive my-2">Dodawanie komentarza</h4>
      </div>
      <div class="card-body">
        <form [formGroup]="commentForm">
          <!--<div class="row">-->
          <!--<div class="col">-->
          <!--<div class="btn-group btn-group-sm" role="group">-->
          <!--<button mdbBtn type="button" color="secondary" size="sm" class="waves-light" mdbRadio="0"-->
          <!--formControlName="type" mdbWavesEffect>Wiadomość-->
          <!--</button>-->
          <!--<button mdbBtn type="button" color="secondary" size="sm" class="waves-light" mdbRadio="1"-->
          <!--formControlName="type" mdbWavesEffect>Email-->
          <!--</button>-->
          <!--<button mdbBtn type="button" color="secondary" size="sm" class="waves-light" mdbRadio="2"-->
          <!--formControlName="type" mdbWavesEffect>Telefon-->
          <!--</button>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <div *ngIf="commentForm.value.type == '1'" class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fReceiverEmail"
                  formControlName="receiverEmail"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                />
                <label class for="fReceiverEmail">Email odbiorcy</label>
              </div>
            </div>
          </div>
          <div *ngIf="commentForm.value.type == '1' || commentForm.value.type == '2'" class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fReceiver"
                  formControlName="receiver"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                />
                <label class for="fReceiver">Odbiorca</label>
              </div>
            </div>
          </div>
          <div *ngIf="commentForm.value.type == '1'" class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fTitle"
                  formControlName="title"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                />
                <label class for="fTitle">Tytuł</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <textarea
                  class="md-textarea w-100"
                  style="height: 200px !important"
                  id="fcomment"
                  type="text"
                  formControlName="comment"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                ></textarea>
                <label for="fcomment">Wiadomość</label>
              </div>
            </div>
          </div>
          <div class="row mb-2" *ngIf="attachmentList.length > 0">
            <div class="col-12">
              <h6>Załączniki ({{ attachmentList.length }})</h6>
              <div style="max-height: 150px; overflow-y: auto">
                <div *ngFor="let f of attachmentList" class="mt-1">
                  <span>{{ f.fileName }}</span>
                  <span class="mr-2" style="float: right; cursor: pointer" (click)="removeAttachment(f)"
                    ><i class="fa fa-times pink-text"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <app-file-uploader (uploadedFile)="addToAttachment($event)"></app-file-uploader>
          <div class="text-center">
            <button
              class="btn btn-default waves-light"
              (click)="onSubmit()"
              [disabled]="commentForm.invalid"
              mdbRippleRadius
            >
              Wyślij
            </button>
            <button class="btn btn-outline-default waves-light" (click)="onCancel()" mdbRippleRadius>Anuluj</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
