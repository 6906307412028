import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DynamicTable, FilterCache, TableHead, TableHeadSetting, ViewSetting } from '../../models/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ListItem } from '../../models/list-item';
import { TableSettingsService } from '../../services/table-settings.service';

@Component({
  selector: 'app-table-main',
  templateUrl: './table-main.component.html',
  styleUrls: ['./table-main.component.scss'],
})
export class TableMainComponent implements OnInit {
  @Input() tableCaption: string;
  @Input() dynamicTable: DynamicTable;
  @Input() firstColumnIcon = '';
  @Input() filterVisible = true;
  @Input() butAddVisible = false;
  @Input() butExportVisible = false;
  @Input() butFilterVisible = true;
  @Input() butColumnVisible = false;
  @Input() tableName: string;
  @Input() showHeader = true;
  @Input() showFooter = true;
  @Input() showFilter = true;
  @Input() enableSorting = true;
  @Input() showPageChoose = true;
  @Input() multiSelect = false;
  @Input() clientSide = false;
  selectedId: number;
  selectedIds: number[] = [];
  @Output() reload = new EventEmitter<any>();
  @Output() selectedIdChange = new EventEmitter<any>();
  @Output() addClicked = new EventEmitter<any>();
  @Output() exportClicked = new EventEmitter<any>();
  @Output() doubleClicked = new EventEmitter<any>();
  @Output() multiClicked = new EventEmitter<any>();
  sortingFieldId: string;
  isFilter: boolean;
  selectedRow = -1;
  columnsSettings: TableHeadSetting[];
  viewSetting: ViewSetting;
  @ViewChild('columnsModal') columnsModal: any;
  @ViewChild('filtersModal') filtersModal: any;
  viewForm: UntypedFormGroup;
  viewSelect: ListItem[];
  selectedView: number;
  isSelectingView = true;
  headers: TableHead[];
  first = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    private tableService: TableSettingsService,
  ) {}

  ngOnInit() {
    this.isFilter = this.dynamicTable.heads.some((item) => !!item.filterValue);
    const sortingIndex = this.dynamicTable.heads.findIndex((item) => item.sortingDirection > 0);
    if (sortingIndex > -1) {
      this.sortingFieldId = this.dynamicTable.heads[sortingIndex].name;
    }
    this.viewForm = this.formBuilder.group({
      viewName: ['', Validators.required],
    });
    if (this.first) {
      this.headers = this.dynamicTable.heads;
    }
  }

  emitReload(isFilter: boolean) {
    this.isFilter = isFilter;
    this.first = false;
    this.clearSelection();
    this.reload.emit(this.dynamicTable);
  }

  filterChange() {
    this.isFilter = this.dynamicTable.heads.some((item) => !!item.filterValue);
    this.dynamicTable.paging.page = 1;

    const filters: FilterCache[] = [];
    this.dynamicTable.heads.forEach((item) => {
      if (item.filterValue) {
        filters.push({ filterField: item.filterField, filterValue: item.filterValue });
      }
    });
    this.tableService.setFiltering(this.dynamicTable.code, filters);
    this.tableService.setPage(this.dynamicTable.code, '1');
    this.emitReload(this.isFilter);
  }

  pageChange() {
    this.emitReload(this.isFilter);
  }

  sortChange(id: string) {
    if (!this.clientSide) {
      if (this.enableSorting) {
        if (this.sortingFieldId) {
          if (id !== this.sortingFieldId) {
            this.dynamicTable.heads.find((item) => item.name === this.sortingFieldId).sortingDirection = 0;
          }
        }
        let sortDirection = this.dynamicTable.heads.find((item) => item.name === id).sortingDirection;
        sortDirection = (sortDirection + 1) % 3;
        this.dynamicTable.heads.find((item) => item.name === id).sortingDirection = sortDirection;
        this.tableService.setSorting(this.dynamicTable.code, id, sortDirection);
        this.tableService.setPage(this.dynamicTable.code, '1');
        this.emitReload(this.isFilter);
      }
    } else {
      if (this.enableSorting) {
        let sortIndex = -1;
        if (this.sortingFieldId) {
          if (id !== this.sortingFieldId) {
            this.dynamicTable.heads.find((item) => item.name === this.sortingFieldId).sortingDirection = 0;
          }
        }
        let sortDirection = this.dynamicTable.heads.find((item) => item.name === id).sortingDirection;
        sortDirection = (sortDirection + 1) % 3;
        this.dynamicTable.heads.find((item) => item.name === id).sortingDirection = sortDirection;
        this.sortingFieldId = id;
        sortIndex = this.dynamicTable.heads.findIndex((item) => item.name === id);
        this.dynamicTable.rows.sort((a, b) => {
          if (sortDirection === 1) {
            return a.attributes[sortIndex] > b.attributes[sortIndex] ? 1 : -1;
          } else if (sortDirection === 2) {
            return a.attributes[sortIndex] < b.attributes[sortIndex] ? 1 : -1;
          } else {
            return 0;
          }
        });
      }
    }
  }

  clearFilter() {
    this.dynamicTable.heads.forEach((item) => (item.filterValue = ''));
    this.filterChange();
    this.headers = this.dynamicTable.heads;
    this.isFilter = false;
    this.emitReload(this.isFilter);
  }

  clearSelection() {
    this.selectedRow = -1;
    this.selectedId = -1;
  }

  setClickedRow(id, i) {
    this.selectedId = id;
    this.selectedRow = i;
    this.selectedIdChange.emit(this.selectedId);
  }
  setDoubleClickedRow(id, i) {
    this.selectedId = id;
    this.selectedRow = i;
    this.doubleClicked.emit(this.selectedId);
  }

  setMultiClickedRow() {
    this.multiClicked.emit(this.selectedIds);
  }

  clickCheckboxRow(event, id, i) {
    event.stopPropagation();
    const index = this.selectedIds.findIndex((item) => item === id);
    if (index < 0) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds.splice(index, 1);
    }
  }

  toggleFilter() {
    this.filterVisible = !this.filterVisible;
  }

  setisFilter(isfilter: boolean) {
    this.isFilter = isfilter;
  }

  setClickedAdd() {
    this.addClicked.emit();
  }

  setClickedExport() {
    this.exportClicked.emit();
  }

  columnsChoiceShow() {
    this.tableService.getViews(this.dynamicTable.code).subscribe((data) => {
      const result: any = data;
      this.viewSelect = result;
    });

    this.viewSetting = new ViewSetting();
    this.viewSetting.columns = [];
    this.dynamicTable.heads.forEach((item) => {
      this.viewSetting.columns.push({
        name: item.name,
        order: 0,
        show: true,
        title: item.title,
      });
    });
    this.columnsModal.show();
  }

  updateCheckedOptions(option, event) {
    option.show = event.checked;
  }

  // saveColumns() {
  //   // this.viewSetting = new ViewSetting();
  //  // this.viewSetting.columns = this.columnsSettings;
  //  // this.viewSetting.filters = [];
  //   // this.viewSetting.filters.push({
  //   //   name: 'UsrName',
  //   //   value: 'ada'
  //   // })
  //
  //   this.dynamicTable.heads.forEach(item => {
  //     if (item.filterValue) {
  //       this.viewSetting.filters.push({
  //         name: item.filterField,
  //         value: item.filterValue
  //       });
  //      // filterParam += '&filter.' + item.filterField + '=' + item.filterValue;
  //     }
  //   });
  //
  //   // this.tableService.saveColumnSettings(this.dynamicTable.code, this.columnsSettings).subscribe((data) =>
  //   //   this.emitReload()
  //   // );
  //   // debugger
  //   this.viewSetting.name = this.viewForm.get('viewName').value;
  //   // this.viewSetting = Object.assign({}, this.viewForm.value);
  //
  //   this.tableService.saveViewSettings(this.dynamicTable.code, this.viewSetting).subscribe((data) =>
  //     this.emitReload()
  //   );
  // }

  // filterChoiceShow() {
  //   // this.filterForm = this.formBuilder.group({
  //   //   name: ['', Validators.required],
  //   // })
  //   this.filtersModal.show();
  // }

  toggleAddViewForm(value) {
    this.isSelectingView = value;
  }

  setView() {
    this.dynamicTable.view = this.viewSelect.find((x) => x.value === String(this.selectedView)).label;
    this.emitReload(this.isFilter);
  }

  saveView() {
    this.dynamicTable.heads.forEach((item) => {
      if (item.filterValue) {
        this.viewSetting.filters.push({
          name: item.filterField,
          value: item.filterValue,
        });
      }
    });
    this.viewSetting.name = this.viewForm.get('viewName').value;
    this.tableService.saveViewSettings(this.dynamicTable.code, this.viewSetting).subscribe(() =>
      this.tableService.getViews(this.dynamicTable.code).subscribe((data) => {
        this.viewSelect = data;
      }),
    );
  }
}

// import {Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
// import {DynamicTable, TableHeadSetting, ViewSetting} from '../../models/table';
// import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// import {ListItem} from '../../models/list-item';
// import {TableSettingsService} from '../../services/table-settings.service';
//
// @Component({
//   selector: 'app-table-main',
//   templateUrl: './table-main.component.html',
//   styleUrls: ['./table-main.component.scss']
// })
// export class TableMainComponent implements OnInit {
//   @Input() tableCaption: string;
//   @Input() dynamicTable: DynamicTable;
//   @Input() firstColumnIcon = 'fa-pencil';
//   @Input() filterVisible = true;
//   @Input() butAddVisible = false;
//   @Input() butExportVisible = false;
//   @Input() tableName: string;
//   selectedId: number;
//   @Output() reload = new EventEmitter<any>();
//   @Output() selectedIdChange = new EventEmitter<any>();
//   @Output() addClicked = new EventEmitter<any>();
//   @Output() exportClicked = new EventEmitter<any>();
//   sortingFieldId: string;
//   isFilter: boolean;
//   selectedRow = -1;
//   columnsSettings: TableHeadSetting[];
//   viewSetting: ViewSetting;
//   @ViewChild('columnsModal') columnsModal: any;
//   @ViewChild('filtersModal') filtersModal: any;
//   viewForm: FormGroup;
//   viewSelect: ListItem[];
//   selectedView: number;
//   isSelectingView = true;
//
//
//   constructor(private formBuilder: FormBuilder,
//               private renderer: Renderer2,
//               private tableService: TableSettingsService) { }
//
//   ngOnInit() {
//     this.viewForm = this.formBuilder.group({
//       viewName: ['', Validators.required],
//     });
//   }
//
//   emitReload() {
//     this.clearSelection();
//     this.reload.emit(this.dynamicTable);
//   }
//
//   filterChange() {
//     this.isFilter = this.dynamicTable.heads.some(item => !!item.filterValue);
//     this.dynamicTable.paging.page = 1;
//     this.emitReload();
//   }
//
//   pageChange() {
//     this.emitReload();
//   }
//
//   sortChange(id: string) {
//     if (this.sortingFieldId) {
//       if (id !== this.sortingFieldId) {
//         this.dynamicTable.heads.find(item => item.name === this.sortingFieldId).sortingDirection = 0;
//       }
//     }
//     let sortDirection = this.dynamicTable.heads.find(item => item.name === id).sortingDirection;
//     sortDirection = (sortDirection + 1) % 3;
//     this.dynamicTable.heads.find(item => item.name === id).sortingDirection = sortDirection;
//     this.sortingFieldId = id;
//     this.dynamicTable.paging.page = 1;
//     this.emitReload();
//   }
//
//   clearFilter() {
//     this.dynamicTable.heads.forEach(item => item.filterValue = '' );
//     this.isFilter = false;
//     this.emitReload();
//   }
//
//   odswiez() {
//     this.emitReload();
//   }
//
//   clearSelection() {
//     this.selectedRow = -1;
//     this.selectedId = -1;
//   }
//
//   setClickedRow(id, i) {
//     this.selectedId = id;
//     this.selectedRow = i;
//     this.selectedIdChange.emit(this.selectedId);
//   }
//
//   toggleFilter() {
//     this.filterVisible = !this.filterVisible;
//   }
//
//   setClickedAdd() {
//     this.addClicked.emit();
//   }
//
//   setClickedExport() {
//     this.exportClicked.emit();
//   }
//
//
//
//   columnsChoiceShow() {
//     this.tableService.getViews(this.dynamicTable.code).subscribe((data) => {
//       const result: any = data;
//       this.viewSelect = result;
//     });
//
//     this.viewSetting = new ViewSetting();
//     this.viewSetting.columns = [];
//     this.dynamicTable.heads.forEach(item => {
//       this.viewSetting.columns.push({
//         name: item.name,
//         order: 0,
//         show: true,
//         title: item.title
//       });
//     })
//     this.columnsModal.show();
//   }
//
//   updateCheckedOptions(option, event) {
//     option.show = event.checked;
//   }
//
//   // saveColumns() {
//   //   // this.viewSetting = new ViewSetting();
//   //  // this.viewSetting.columns = this.columnsSettings;
//   //  // this.viewSetting.filters = [];
//   //   // this.viewSetting.filters.push({
//   //   //   name: 'UsrName',
//   //   //   value: 'ada'
//   //   // })
//   //
//   //   this.dynamicTable.heads.forEach(item => {
//   //     if (item.filterValue) {
//   //       this.viewSetting.filters.push({
//   //         name: item.filterField,
//   //         value: item.filterValue
//   //       });
//   //      // filterParam += '&filter.' + item.filterField + '=' + item.filterValue;
//   //     }
//   //   });
//   //
//   //   // this.tableService.saveColumnSettings(this.dynamicTable.code, this.columnsSettings).subscribe((data) =>
//   //   //   this.emitReload()
//   //   // );
//   //   // debugger
//   //   this.viewSetting.name = this.viewForm.get('viewName').value;
//   //   // this.viewSetting = Object.assign({}, this.viewForm.value);
//   //
//   //   this.tableService.saveViewSettings(this.dynamicTable.code, this.viewSetting).subscribe((data) =>
//   //     this.emitReload()
//   //   );
//   // }
//
//   // filterChoiceShow() {
//   //   // this.filterForm = this.formBuilder.group({
//   //   //   name: ['', Validators.required],
//   //   // })
//   //   this.filtersModal.show();
//   // }
//
//   toggleAddViewForm(value) {
//     this.isSelectingView = value;
//   }
//
//   setView() {
//     this.dynamicTable.view = this.viewSelect.find(x => x.value === String(this.selectedView)).label;
//     this.emitReload();
//   }
//
//   saveView() {
//     this.dynamicTable.heads.forEach(item => {
//       if (item.filterValue) {
//         this.viewSetting.filters.push({
//           name: item.filterField,
//           value: item.filterValue
//         });
//       }
//     });
//     this.viewSetting.name = this.viewForm.get('viewName').value;
//     this.tableService.saveViewSettings(this.dynamicTable.code, this.viewSetting).subscribe(() =>
//       this.tableService.getViews(this.dynamicTable.code).subscribe((data) => {
//         this.viewSelect = data;
//       })
//     );
//   }
// }
