<p>Funkcjonalność w przygotowaniu...</p>

<!--<ng-template [ngIf]="trainingViewStatus===0" ]>-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <app-table-main *ngIf="trainingsTable"-->
<!--                      (reload)="trainingsReload()"-->
<!--                      (selectedIdChange)="trainingEdit($event)"-->
<!--                      tableCaption="Planowane szkolenia"-->
<!--                      [(dynamicTable)]="trainingsTable"-->
<!--                      [butAddVisible]="false"-->
<!--                      [butExportVisible]="false"-->
<!--      ></app-table-main>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
<!--&lt;!&ndash;<ng-template [ngIf]="caseViewStatus===1">&ndash;&gt;-->
<!--  &lt;!&ndash;<div class="row">&ndash;&gt;-->
<!--    &lt;!&ndash;<div class="col-12">&ndash;&gt;-->
<!--      &lt;!&ndash;<app-case-add&ndash;&gt;-->
<!--        &lt;!&ndash;[refCustomerId]="refCustomerId"&ndash;&gt;-->
<!--        &lt;!&ndash;(returnClicked)="caseReturn($event)"&ndash;&gt;-->
<!--      &lt;!&ndash;&gt;&ndash;&gt;-->
<!--      &lt;!&ndash;</app-case-add>&ndash;&gt;-->
<!--    &lt;!&ndash;</div>&ndash;&gt;-->
<!--  &lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-template>&ndash;&gt;-->
<!--<ng-template [ngIf]="trainingViewStatus===2">-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <app-training-view-->
<!--        [refTrainingId]="trainingId"-->
<!--        (returnClicked)="trainingReturn($event)"-->
<!--      >-->
<!--      </app-training-view>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
