import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SoftwareUpdateStatusItem } from './models/software-update-status';
import { map, retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class SoftwareUpdatesStatusService implements OnDestroy {
  private apiUrl = 'api/cp/softwareUpdates/client';
  private allStatuses$!: Observable<SoftwareUpdateStatusItem[]>;
  private cancellationToken = new Subject();

  constructor(private http: HttpClient) {}

  getAllStatuses(): Observable<SoftwareUpdateStatusItem[]> {
    return this.allStatuses$.pipe(map((res) => res as SoftwareUpdateStatusItem[]));
  }

  startPolling(groupGuid: string) {
    this.allStatuses$ = timer(1, 3000).pipe(
      switchMap(() => this.http.get<SoftwareUpdateStatusItem[]>(this.apiUrl + `/status/grouped/${groupGuid}`)),
      retry(),
      tap(console.log),
      share(),
      takeUntil(this.cancellationToken),
    );
  }

  stopPolling() {
    this.cancellationToken.next(null);
  }

  ngOnDestroy() {
    this.cancellationToken.next(null);
  }
}
