import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let accessToken = sessionStorage.getItem("access_token");
    // if(accessToken)
    // {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${accessToken}`
    //     }
    //   });
    // }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          this.auth.logout();
          this.router.navigate(['logowanie']);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      }),
    );
  }
}
