import { DashboardComponent } from './components/dashboard/dashboard.component';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';
import { CaseResolve, TrainingResolve } from './dashboard-resolve.service';
import { NgModule } from '@angular/core';

const DASHBOARD: Route[] = [
  {
    path: 'panel',
    component: <any>DashboardComponent,
    canActivate: [AuthGuard],
    resolve: {
      cases: CaseResolve,
      trainings: TrainingResolve,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(DASHBOARD)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
