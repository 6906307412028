<ng-template [ngIf]="messageViewStatus === 0" ]>
  <div class="row">
    <div class="col-12">
      <app-table-main
        *ngIf="messagesTable"
        (reload)="messagesReload()"
        (selectedIdChange)="messageShow($event)"
        tableCaption="Wiadomości, komunikaty"
        [dynamicTable]="messagesTable"
        [firstColumnIcon]="false"
      ></app-table-main>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="messageViewStatus === 3">
  <div class="row">
    <div class="col-12">
      <app-message-show [refMessageId]="messageId" (returnClicked)="messageReturn($event)"> </app-message-show>
    </div>
  </div>
</ng-template>
