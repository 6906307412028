<div class="modal-content">
  <div class="modal-header light-blue darken-3 white-text">
    <h4 class="modal-title w-100" id="myModalLabel">{{ 'Postęp aktualizacji aplikacji' }}</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="statusInfo.length > 0" class="row mt-1">
      <div class="col-12 table-responsive" style="overflow-y: scroll">
        <table mdbTable small="true">
          <thead>
            <tr>
              <th style="width: 30px"><span class="mx-2 my-2">Status</span></th>
              <th style="width: 50px"><span class="mx-2 my-2">Postęp</span></th>
              <th><span class="mx-2 my-2">Opis</span></th>
              <th><span class="mx-2 my-2">Aplikacja</span></th>
              <th><span class="mx-2 my-2">Wersja</span></th>
              <th><span class="mx-2 my-2">Komunikat błędu</span></th>
              <th><span class="mx-2 my-2"></span></th>
            </tr>
          </thead>
          <tbody style="max-height: 500px; overflow-y: scroll">
            <tr *ngFor="let software of statusInfo" [ngClass]="{ 'text-danger': software.stage === 3 }">
              <td style="width: 30px; text-align: center">
                <i
                  class="fas"
                  [ngClass]="{
                    'fa-check text-success': software.stage === 2,
                    'fa-hourglass text-info': software.stage === 0,
                    'fa-paper-plane text-info': software.stage === 1 && software.progress === 0,
                    'fa-sync text-info': software.stage === 1 && software.progress > 0 && software.progress < 100,
                    'fa-exclamation-triangle text-danger': software.stage === 3,
                    'fa-spin': software.progress > 0 && software.progress < 100,
                  }"
                ></i>
              </td>
              <td style="width: 50px">
                <progress min="0" max="100" value="{{ software.progress }}" class="text-success" style="height: 20px">
                  {{ software.progress }}%
                </progress>
              </td>
              <td>{{ software.description }}</td>
              <td>{{ software.application }}</td>
              <td>{{ software.version }}</td>
              <td>{{ software.errorMessage }}</td>
              <td>
                <div class="text-right">
                  <button
                    *ngIf="software.stage === 3"
                    (click)="tryAgain(software.id)"
                    class="btn btn-default waves-light btn-sm px-3"
                    title="Ponów"
                  >
                    Ponów
                  </button>
                  <button
                    *ngIf="software.stage === 2 && software.url"
                    (click)="openLink(software.url)"
                    class="btn btn-default waves-light btn-sm px-3"
                    title="Otwórz link"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <button
                    *ngIf="software.stage === 2 || software.stage === 3"
                    (click)="checkAppVersion(software.id)"
                    class="btn btn-default waves-light btn-sm px-3"
                  >
                    Wersja
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer row">
    <div class="col-12 text-center mt-2">
      <div>
        <div class="chip text-black bg-gray">
          {{ 'Przetworzono ' + (successCount + errorCount) + ' z ' + totalCount }}
        </div>
        <div *ngIf="successCount" class="chip text-white bg-success">{{ 'Zakończone - ' + successCount }}</div>
        <div *ngIf="inProgressCount" class="chip text-white bg-info">{{ 'W trakcie - ' + inProgressCount }}</div>
        <div *ngIf="waitingCount" class="chip text-white bg-info">{{ 'Oczekuje - ' + waitingCount }}</div>
        <div *ngIf="errorCount" class="chip text-white bg-danger">{{ 'Błąd - ' + errorCount }}</div>
      </div>
    </div>
    <div class="col">
      <h5>
        <i class="fas fa-hourglass text-info mr-3" *ngIf="runningStage === 0"></i>
        <i class="fas fa-sync fa-spin mr-3" *ngIf="runningStage === 1"></i>
        <i class="fas fa-check text-success mr-3" *ngIf="runningStage === 2"></i>
        <i class="fas fa-exclamation-triangle text-danger mr-3" *ngIf="runningStage === 3"></i>
        {{ stageMessage }}
      </h5>
    </div>
    <div class="col text-center">
      <button class="btn btn-default waves-light" (click)="onCloseClick()">Zamknij</button>
    </div>
    <div class="col text-right">
    </div>
  </div>
</div>
