import { Component, Input, OnInit } from '@angular/core';
import { DynamicTable } from '../../../shared/models/table';
import { CasesService } from '../../cases.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../auth/models/user';
import { TableSettingsService } from '../../../shared/services/table-settings.service';
import { CaseExternalFilter } from '../../models/case';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss'],
})
export class CaseListComponent implements OnInit {
  casesTable: DynamicTable;
  caseId = 0;
  caseViewStatus = 0;
  activeChecked = true;
  searchInComment = '';
  user: User;
  @Input() refCustomerId = 0;

  constructor(
    private authService: AuthService,
    private caseService: CasesService,
    private tableSettingsService: TableSettingsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.user = this.authService.getCurrentUser();
    if (this.refCustomerId === 0) {
      this.casesTable = this.route.snapshot.data['cases'];
    } else {
      const pageParam = 'paging.page=1&paging.recordsPerPage=10';
      this.caseService.getCases(pageParam).subscribe((data) => {
        const result: any = data;
        this.casesTable = result;
      });
    }
  }

  casesReload(resetPage = false) {
    this.countFilterExternal();
    if (resetPage) {
      this.tableSettingsService.setPage('CasesList', 1);
    }

    let parameters = this.tableSettingsService.getRequestParams('CasesList');
    if (this.refCustomerId) {
      parameters += '&filter.CasCusId=' + this.refCustomerId;
    }
    this.caseService.getCases(parameters).subscribe((data) => {
      this.casesTable = data;
    });
  }

  countFilterExternal() {
    const filter: CaseExternalFilter = { onlyActive: this.activeChecked, searchComments: this.searchInComment };
    this.tableSettingsService.setFilteringExternal('CasesList', filter);
  }

  caseAdd() {
    this.caseId = -1;
    this.caseViewStatus = 1;
  }

  caseEdit(id) {
    this.caseId = id;
    this.caseViewStatus = 2;
  }

  caseReturn(reload) {
    this.casesReload();
    this.caseViewStatus = 0;
  }

  caseExport() {
    // const pageParam = 'code=' + this.casesTable.code + '&view=' + this.casesTable.view
    //   + '&paging.page=' + this.casesTable.paging.page + '&paging.recordsPerPage='
    //   + this.casesTable.paging.recordsPerPage;
    // let filterParam = '';
    // this.casesTable.heads.forEach(item => {
    //   if (item.filterValue) {
    //     filterParam += '&filter.' + item.filterField + '=' + item.filterValue;
    //   }
    // });
    // let sortParam = '';
    // const sortField = this.casesTable.heads.find(item => item.sortingDirection > 0);
    // if (sortField) {
    //   sortParam = '&sort=' + sortField.name + '&sortDirection=' + sortField.sortingDirection;
    // }
    // this.caseService.exportCases(pageParam + filterParam + sortParam);
    this.countFilterExternal();

    let parameters = this.tableSettingsService.getRequestParams('CasesList');
    if (this.refCustomerId) {
      parameters += '&filter.CasCusId=' + this.refCustomerId;
    }
    this.caseService.exportCases(parameters);
  }

  getActiveValue() {
    console.log('Checked value:', this.activeChecked);
    this.casesReload();
  }

  searchComments() {
    this.casesReload();
  }
}
