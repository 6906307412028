import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceLimitListComponent } from './components/service-limit-list/service-limit-list.component';
import { ServiceListComponent } from './components/service-list/service-list.component';
import { ServiceViewComponent } from './components/service-view/service-view.component';
import { ServiceLimitsResolve, ServicesResolve } from './services-resolve.service';
import { MDBRootModules } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [ServiceLimitListComponent, ServiceListComponent, ServiceViewComponent],
  imports: [CommonModule, MDBRootModules, SharedModule],
  providers: [ServiceLimitsResolve, ServicesResolve],
})
export class ServicesModule {}
