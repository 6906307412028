import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../../services/layout.service';
import { User } from '../../../auth/models/user';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
})
export class MainHeaderComponent implements OnInit {
  userName: string;
  isNotAdministration: boolean;
  user: User;

  constructor(
    private authService: AuthService,
    public layoutService: LayoutService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isNotAdministration = true;
    this.user = this.authService.getCurrentUser();
    this.userName = this.user.firstName + ' ' + this.user.surname;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['logowanie']);
  }

  settings() {
    if (this.route.snapshot.queryParams['returnUrl'] === undefined) {
      this.router.navigate(['ustawienia'], { queryParams: { returnUrl: this.router.url } });
    } else {
      this.router.navigate(['ustawienia'], {
        queryParams: { returnUrl: this.route.snapshot.queryParams['returnUrl'] },
      });
    }
    this.layoutService.changeActiveElement('settings');
  }

  settingsNotification() {
    if (this.route.snapshot.queryParams['returnUrl'] === undefined) {
      this.router.navigate(['ustawienia/powiadomienia'], { queryParams: { returnUrl: this.router.url } });
    } else {
      this.router.navigate(['ustawienia/powiadomienia'], {
        queryParams: { returnUrl: this.route.snapshot.queryParams['returnUrl'] },
      });
    }
    this.layoutService.changeActiveElement('settings');
  }

  changePassword() {
    if (this.route.snapshot.queryParams['returnUrl'] === undefined) {
      this.router.navigate(['zmiana-hasla'], { queryParams: { returnUrl: this.router.url } });
    } else {
      this.router.navigate(['zmiana-hasla'], {
        queryParams: { returnUrl: this.route.snapshot.queryParams['returnUrl'] },
      });
    }
    this.layoutService.changeActiveElement('password');
  }

  goToCases() {
    this.router.navigate(['sprawy']);
    this.layoutService.changeActiveElement('case');
  }

  goToLicences() {
    this.router.navigate(['licencje']);
    this.layoutService.changeActiveElement('licence');
  }

  goToTrainings() {
    this.router.navigate(['szkolenia']);
    this.layoutService.changeActiveElement('training');
  }

  goToDocumentation() {
    this.router.navigate(['dokumentacja']);
    this.layoutService.changeActiveElement('documentation');
  }

  goToDashboard() {
    this.router.navigate(['panel']);
    this.layoutService.changeActiveElement('panel');
  }

  goToServices() {
    this.router.navigate(['uslugi']);
    this.layoutService.changeActiveElement('services');
  }

  goToUpdates() {
    this.router.navigate(['aktualizacje-oprogramowania']);
    this.layoutService.changeActiveElement('software-updates');
  }

  goToMessages() {
    this.router.navigate(['wiadomosci']);
    this.layoutService.changeActiveElement('messages');
  }

  goToAdministration() {
    this.router.navigate(['administracja/uzytkownicy']);
    this.layoutService.changeActiveElement('administration');
  }
}
