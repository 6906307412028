import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttachmentDocument } from '../../models/attachment-document';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
})
export class DocumentListComponent implements OnInit {
  @Input() documents: Array<AttachmentDocument>;
  @Output() downloadFile = new EventEmitter<any>();
  @Output() uploadFile = new EventEmitter<any>();
  @Output() replaceFile = new EventEmitter<any>();
  @Output() deleteFile = new EventEmitter<any>();
  @Input() showDelete = true;
  @Input() detailsView = true;

  constructor() {}

  ngOnInit(): void {}

  onDownloadFile(id) {
    this.downloadFile.emit(id);
  }

  onUploadFile(id) {
    this.uploadFile.emit(id);
  }

  onReplaceFile(documentItem: AttachmentDocument) {
    this.replaceFile.emit(documentItem);
  }

  onDeleteFile(id) {
    this.deleteFile.emit(id);
  }
}
