import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { forkJoin } from 'rxjs';
import { ICustomerContact } from '../../models/account';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent implements OnInit {
  customerContactItem: ICustomerContact;
  isDataLoaded: boolean;

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    const customerContact = this.settingsService.getCustomerContact();
    forkJoin([customerContact]).subscribe((results) => {
      this.customerContactItem = results[0];
      this.isDataLoaded = true;
    });
  }
}
