import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingsService } from '../../trainings.service';
import { TrainingApplicationItem } from '../../models/training';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-application-add-edit',
  templateUrl: './application-add-edit.component.html',
  styleUrls: ['./application-add-edit.component.scss'],
})
export class ApplicationAddEditComponent implements OnInit {
  applicationForm: UntypedFormGroup;
  applicationItem: TrainingApplicationItem;
  isDataLoaded: boolean = false;
  @Input() refCustomerId = 0;
  @Input() refTrainingId = 0;
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private trainingsService: TrainingsService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    // if (!this.isAdd) {
    //   if (this.refContactId === 0) {
    //     this.applicationItem= this.route.snapshot.data['contact'];
    //     this.applicationForm = this.buildContactForm();
    //     this.isDataLoaded = true;
    //   } else {
    //     const sContact = this.trainingsService. .getContactById(this.refContactId);
    //     forkJoin([sContact]).subscribe(results => {
    //       this.contactItem = results[0];
    //       this.contactForm = this.buildContactForm();
    //       this.isDataLoaded = true;
    //     });
    //   }
    // } else {
    this.applicationForm = this.buildApplicationForm();
    this.isDataLoaded = true;
    // }
  }

  buildApplicationForm() {
    const currentUser = this.authService.getCurrentUser();
    return this.formBuilder.group({
      email: [currentUser.email, Validators.required],
      firstName: [currentUser.firstName, Validators.required],
      lastName: [currentUser.surname, Validators.required],
      nip: [currentUser.nip, Validators.required],
      customerName: [currentUser.customer, Validators.required],
      phone: [currentUser.phone, Validators.required],
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    this.applicationItem = Object.assign({}, this.applicationForm.value);
    this.applicationItem.trainingId = this.refTrainingId;
    this.trainingsService.addApplication(this.applicationItem).subscribe(
      (data) => {
        this.onSubmitSuccess();
      },
      (error) => {
        this.onSubmitFailure();
        // this.alertService.error(error);
        // this.loading = false;
      },
    );
  }

  private onSubmitSuccess() {
    this.save.emit();
  }

  private onSubmitFailure() {
    console.log('Error on save comment');
    const options = { closeButton: true, tapToDismiss: false, positionClass: 'toast-bottom-right' };
    this.toastService.error('Wystąpił błąd podczas rejestracji zgłoszenia', 'Błąd', options);
  }
}
