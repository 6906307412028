<!--<p>-->
<!--  Konfigueracja powiadomień w przygotowaniu...-->
<!--</p>-->
<!--<div>-->
<!--<div class="card-body">-->
<div *ngIf="isDataLoaded" class="bg-white">
  <form [formGroup]="notificationCaseForm">
    <div class="row">
      <div class="col-md-6">
        <div class="card-body h-100">
          <h5 class="my-4 pb-2">Moje sprawy</h5>
          <ul class="text-left list-unstyled">
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="ownAdded">Dodanie</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="ownAssigned">Przypisanie</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="ownCommentAdded">Dodanie komentarza</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="ownClosingProposal">Propozycja zamknięcią</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="ownClosed">Zamknięcie</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="ownAutoClose">Automatyczne zamknięcie</mdb-checkbox>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card-body h-100">
          <h5 class="my-4 pb-2">Wszystkie sprawy</h5>
          <ul class="text-left list-unstyled">
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="added">Dodanie</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="assigned">Przypisanie</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="commentAdded">Dodanie komentarza</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="closingProposal">Propozycja zamknięcią</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="closed">Zamknięcie</mdb-checkbox>
            </li>
            <li class="mb-2">
              <mdb-checkbox [checked]="true" formControlName="autoClose">Automatyczne zamknięcie</mdb-checkbox>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center mt-5 mb-3">
      <button
        (click)="Submit()"
        [disabled]="notificationCaseForm.invalid"
        class="btn btn-default waves-light"
        mdbRippleRadius
      >
        Zapisz
      </button>
      <!--      <button (click)="Cancel()" class="btn btn-outline-default waves-light" mdbRippleRadius>Anuluj</button>-->
    </div>
  </form>
  <!--</div>-->
  <!--</div>-->
</div>
