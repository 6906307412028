import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UpdatesService } from '../../updates.service';

@Component({
  selector: 'app-full-view',
  templateUrl: './full-view.component.html',
})
export class FullViewComponent implements OnInit {
  viewType = 'typeVersion';
  isDataLoaded = false;
  isEnabled = false;

  constructor(private updatesService: UpdatesService) {}

  ngOnInit(): void {
    forkJoin([this.updatesService.getIsEnabled()]).subscribe((results) => {
      this.isEnabled = results[0];
      this.isDataLoaded = true;
    });
  }

  startUpdate() {
    this.viewType = 'typeUpdate';
  }
}
