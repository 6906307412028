import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DynamicTable } from '../shared/models/table';
import { MessagesService } from './messages.service';

@Injectable()
export class MessagesResolve implements Resolve<DynamicTable> {
  constructor(private messagesService: MessagesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.messagesService.getAll('');
  }
}
