import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { SoftwareUpdatesToolsService } from '../software-updates.tools.service';
import { forkJoin } from 'rxjs';
import { SoftwareUpdatesService } from '../software-updates.service';
import { SoftwareMainVersionItem } from '../models/software-main-version-item';
import { SoftwareUpdateManualDialogComponent } from '../modals/software-update-manual-dialog/software-update-manual-dialog.component';

@Component({
  selector: 'app-software-main-version',
  templateUrl: './software-main-version.component.html',
})
export class SoftwareMainVersionComponent implements OnInit {
  @Input() refCustomerId: number = 0;
  softwareMainVersionItem!: SoftwareMainVersionItem;
  isDataLoaded = false;
  buttonMessage = 'Odśwież';
  modalRef!: MDBModalRef;
  constructor(
    private toastService: ToastService,
    private softwareUpdatesService: SoftwareUpdatesService,
    private modalService: MDBModalService,
    private softwareUpdatesToolsService: SoftwareUpdatesToolsService,
  ) {}

  ngOnInit(): void {
    forkJoin([this.softwareUpdatesService.getSoftwareMainVersion(true)]).subscribe((results) => {
      this.softwareMainVersionItem = results[0];
      this.isDataLoaded = true;
    });
  }

  refreshClick() {
    this.buttonMessage = 'W toku...';
    this.softwareUpdatesService.getSoftwareMainVersion(true).subscribe(
      (data) => {
        this.softwareMainVersionItem = data;
        this.buttonMessage = 'Odśwież';
      },
      (error) => {
        this.buttonMessage = 'Błąd';
        this.toastService.error('Brak poprawnej komunikacji z serwisem aktualizacji.', 'Błąd');
      },
    );
  }

  clickUpdateManual() {
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-scrollable modal-full-height modal-top w-100 mx-auto',
      containerClass: '',
      animated: true,
    };
    this.modalRef = this.modalService.show(SoftwareUpdateManualDialogComponent, modalOptions);
    this.modalRef.content.action.subscribe((result: any) => {
      if (result.status) {
        this.softwareUpdatesService.setManualUpdate(result.definitionItem).subscribe(
          (groupGuid: string) => {
            this.toastService.info('Aktualizacje zostały dodane do wykonania.', 'Potwierdzenie');
            result.watch ? this.softwareUpdatesToolsService.showProgress(groupGuid, this.reload()) : this.reload();
          },
          () => {
            this.toastService.error('Wystąpił bład podczas zapisywnaia danych definicji aktualizacji.', 'Błąd');
          },
        );
      }
      this.modalRef.hide();
    });
  }

  clickShowProgress() {
    this.softwareUpdatesService.getActiveUpdatesGuids().subscribe((groupGuids: string[]) => {
      if (groupGuids.length === 0) {
        this.toastService.info('Brak aktywnych aktualizacji.', 'Informacja');
        return;
      }
      this.softwareUpdatesToolsService.showProgress(groupGuids[0], this.reload());
    });
  }

  reload() {}
}
