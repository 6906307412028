import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkinComponent } from './components/skin/skin.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { SettingsComponent } from './components/settings/settings.component';
import { SharedModule } from '../shared/shared.module';
import { UserDataComponent } from './components/user-data/user-data.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [SkinComponent, NotificationsComponent, SettingsComponent, UserDataComponent],
})
export class SettingsModule {}
