import { Injectable } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { SoftwareUpdateProgressDialogComponent } from './modals/software-update-progress-dialog/software-update-progress-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SoftwareUpdatesToolsService {
  modalRef!: MDBModalRef;
  constructor(private modalService: MDBModalService) {}

  public showProgress(groupGuid: string, callback: any) {
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-scrollable modal-full-height modal-top w-100 mx-auto',
      containerClass: '',
      animated: true,
      data: {
        groupGuid: groupGuid,
      },
    };
    this.modalRef = this.modalService.show(SoftwareUpdateProgressDialogComponent, modalOptions);
    this.modalRef.content.action.subscribe(() => {
      if (callback) {
        callback();
      }
      this.modalRef.hide();
    });
  }
}
