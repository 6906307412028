import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DynamicTable } from '../shared/models/table';
import { DocumentationService } from './documentation.service';

@Injectable()
export class DocumentationResolve implements Resolve<DynamicTable> {
  constructor(private documentationService: DocumentationService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.documentationService.getDocumentation('');
  }
}
