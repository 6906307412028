<div class="modal-content">
  <div class="modal-header light-blue darken-3 white-text">
    <h4 class="modal-title w-100" id="myModalLabel">{{ 'Weryfikacja aplikacji' }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 table-responsive" style="overflow-y: scroll">
        <table *ngIf="!isChecking" mdbTable small="true">
          <thead>
            <tr>
              <td><span class="mx-2 my-2">&nbsp;</span></td>
              <td><span class="mx-2 my-2">Aktualna</span></td>
              <td><span class="mx-2 my-2">Oczekiwana</span></td>
            </tr>
          </thead>
          <tbody style="max-height: 500px; overflow-y: scroll">
            <tr>
              <td>Wersja</td>
              <td>{{ versionInfo.applicationVersion }}</td>
              <td>{{ versionInfo.applicationVersion }}</td>
            </tr>
            <tr>
              <td>Kompilacja Ośrodka</td>
              <td>{{ versionInfo.osrodekRuntimeVersion }}</td>
              <td>{{ versionInfo.osrodekRuntimeVersion }}</td>
            </tr>
            <tr>
              <td>Kompilacja aplikacji dla Ośrodka</td>
              <td>{{ versionInfo.osrodekCompilationVersion }}</td>
              <td>{{ versionInfo.osrodekCompilationVersion }}</td>
            </tr>
            <tr>
              <td>Kompilacja Mapy</td>
              <td>{{ versionInfo.geoinfoRuntimeVersion }}</td>
              <td>{{ versionInfo.geoinfoRuntimeVersion }}</td>
            </tr>
            <tr>
              <td>Kompilacja aplikacji dla Mapy</td>
              <td>{{ versionInfo.geoinfoCompilationVersion }}</td>
              <td>{{ versionInfo.geoinfoCompilationVersion }}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="isChecking" class="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Wczytywanie danych...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer row">
    <button class="btn btn-default waves-light" (click)="closeClick()">Zamknij</button>
  </div>
</div>
