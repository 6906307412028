<div class="container">
  <div class="row">
    <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">
      <div class="card wow fadeIn" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s">
        <div class="card-body">
          <div class="form-header light-blue darken-3">
            <h3>Panel Klienta Systherm-Info</h3>
          </div>
          <mdb-card-title>
            <h4 class="mb-3">Resetowanie hasła</h4>
          </mdb-card-title>
          <mdb-card-text>
            <form [formGroup]="passwordForm">
              <div class="md-form">
                <input
                  class="form-control"
                  id="floginEmail"
                  type="email"
                  formControlName="loginEmail"
                  mdbInput
                  mdbValidate
                  data-error="Wypełnij pole"
                  [validateSuccess]="false"
                />
                <label class for="floginEmail">Login (email użytkownika)</label>
              </div>
              <p class="blue-text" *ngIf="resultStatus" [ngClass]="{ 'red-text': isError, 'blue-text': !isError }">
                {{ resultStatus }}
              </p>
              <div class="text-center">
                <button
                  class="btn btn-default waves-light"
                  (click)="submit()"
                  [disabled]="passwordForm.invalid"
                  mdbRippleRadius
                >
                  Wyślij zgłoszenie
                </button>
                <button class="btn btn-outline-default waves-light ml-2" [routerLink]="['/logowanie']" mdbRippleRadius>
                  Anuluj
                </button>
              </div>
            </form>
          </mdb-card-text>
        </div>
      </div>
    </div>
  </div>
</div>
