import { Component, Input, OnInit } from '@angular/core';
import { DynamicTable } from '../../../shared/models/table';
import { ServicesService } from '../../services.service';
import { TableSettingsService } from '../../../shared/services/table-settings.service';

@Component({
  selector: 'app-service-limit-list',
  templateUrl: './service-limit-list.component.html',
  styleUrls: ['./service-limit-list.component.scss'],
})
export class ServiceLimitListComponent implements OnInit {
  @Input() serviceLimitTable: DynamicTable;
  constructor(
    private servicesService: ServicesService,
    private tableSettingsService: TableSettingsService,
  ) {}

  ngOnInit(): void {}

  serviceLimitsReload(resetPage = false) {
    const parameters = this.tableSettingsService.getRequestParams('ServiceLimitCustomerList');
    this.servicesService.getServiceLimits(parameters).subscribe((data) => {
      this.serviceLimitTable = data;
    });
  }
}
