import { Component, OnInit } from '@angular/core';
import { DynamicTable } from '../../../shared/models/table';
import { User } from '../../../auth/models/user';
import { AuthService } from '../../../auth/auth.service';
import { TableSettingsService } from '../../../shared/services/table-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesService } from '../../messages.service';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
})
export class MessageListComponent implements OnInit {
  messagesTable: DynamicTable;
  messageId = 0;
  messageViewStatus = 0;
  user: User;

  constructor(
    private authService: AuthService,
    private messageService: MessagesService,
    private tableSettingsService: TableSettingsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.user = this.authService.getCurrentUser();
    this.messagesTable = this.route.snapshot.data['messages'];
  }

  messagesReload(resetPage = false) {
    if (resetPage) {
      this.tableSettingsService.setPage('CPMessagesList', 1);
    }

    const parameters = this.tableSettingsService.getRequestParams('CPMessagesList');
    this.messageService.getAll(parameters).subscribe((data) => {
      this.messagesTable = data;
    });
  }

  messageShow(id) {
    this.messageId = id;
    this.messageViewStatus = 3;
  }

  messageReturn(reload) {
    this.messagesReload();
    this.messageViewStatus = 0;
  }
}
