import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationCaseItem } from '../../models/notifications';
import { SettingsService } from '../../settings.service';
import { forkJoin } from 'rxjs';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notificationCaseForm: UntypedFormGroup;
  notificationCaseItem: NotificationCaseItem;
  isDataLoaded = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private settingsService: SettingsService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    const notification = this.settingsService.getNotificationCase();
    forkJoin([notification]).subscribe((results) => {
      this.notificationCaseItem = results[0];
      this.notificationCaseForm = this.buildNotificationCaseForm();
      this.isDataLoaded = true;
    });
  }

  buildNotificationCaseForm() {
    return this.formBuilder.group({
      added: [this.notificationCaseItem.added],
      assigned: [this.notificationCaseItem.assigned],
      closed: [this.notificationCaseItem.closed],
      closingProposal: [this.notificationCaseItem.closingProposal],
      autoClose: [this.notificationCaseItem.autoClose],
      commentAdded: [this.notificationCaseItem.commentAdded],
      ownAdded: [this.notificationCaseItem.ownAdded],
      ownAssigned: [this.notificationCaseItem.ownAssigned],
      ownClosed: [this.notificationCaseItem.ownClosed],
      ownClosingProposal: [this.notificationCaseItem.ownClosingProposal],
      ownAutoClose: [this.notificationCaseItem.ownAutoClose],
      ownCommentAdded: [this.notificationCaseItem.ownCommentAdded],
    });
  }

  Submit() {
    this.notificationCaseItem = Object.assign({}, this.notificationCaseForm.value);

    this.settingsService.updateNotificationCase(this.notificationCaseItem).subscribe(
      (data) => {
        this.SubmitSuccess();
      },
      (error) => {
        this.SubmitFailure();
      },
    );
  }

  private SubmitSuccess() {
    const options = {
      closeButton: true,
      tapToDismiss: true,
      progressBar: true,
      positionClass: 'md-toast-bottom-right',
    };
    this.toastService.success('Zapisano konfigurację', 'Potwierdzenie', options);
  }

  private SubmitFailure() {
    const options = {
      closeButton: true,
      tapToDismiss: true,
      progressBar: true,
      positionClass: 'md-toast-bottom-right',
    };
    this.toastService.error('Wystąpił bład podczas zapisu konfiguracji powiadomień', 'Błąd', options);
  }
}
