import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoftwareMainVersionItem } from '../../models/software-main-version-item';
import { UpdatesService } from '../../updates.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-software-main-version',
  templateUrl: './software-main-version.component.html',
})
export class SoftwareMainVersionComponent implements OnInit {
  @Input() refCustomerId: number;
  @Output() refreshClicked = new EventEmitter<any>();
  softwareMainVersionItem: SoftwareMainVersionItem;
  isDataLoaded = false;
  buttonMessage = 'Odśwież';

  constructor(private updatesService: UpdatesService) {}

  ngOnInit(): void {
    forkJoin([this.updatesService.getSoftwareMainVersion(false)]).subscribe((results) => {
      this.softwareMainVersionItem = results[0];
      this.isDataLoaded = true;
    });
  }

  refreshClick() {
    this.buttonMessage = 'W toku...';
    this.updatesService.getSoftwareMainVersion(true).subscribe((data) => {
      this.softwareMainVersionItem = data;
      this.refreshClicked.emit();
      this.buttonMessage = 'Odśwież';
    });
  }
}
