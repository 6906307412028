<ng-template [ngIf]="caseViewStatus === 0" ]>
  <form class="form-inline my-0" style="margin-top: -25px !important; margin-bottom: -25px !important">
    <div class="form-check mb-2 mr-sm-2">
      <input
        class="form-check-input"
        type="checkbox"
        id="activeChecked"
        name="activeChecked"
        [(ngModel)]="activeChecked"
        (change)="getActiveValue()"
      />
      <label class="form-check-label" for="activeChecked"> Aktywne zgłoszenia </label>
    </div>
    <div class="md-form ml-5">
      <input
        type="text"
        class="form-control mb-2 mr-sm-2"
        style="min-width: 300px"
        id="searchInComment"
        name="searchInComment"
        [(ngModel)]="searchInComment"
        (change)="searchComments()"
        mdbInput
        mdbValidate
        [validateSuccess]="false"
      />
      <label for="searchInComment">Szukanie po treści zgłoszenia</label>
    </div>
  </form>

  <div class="row">
    <div class="col-12">
      <app-table-main
        *ngIf="casesTable"
        (reload)="casesReload()"
        (selectedIdChange)="caseEdit($event)"
        tableCaption="Sprawy"
        [dynamicTable]="casesTable"
        [butAddVisible]="user.contactRights.caseCreate"
        [butExportVisible]="user.contactRights.caseExport"
        (addClicked)="caseAdd()"
        [firstColumnIcon]="false"
        (exportClicked)="caseExport()"
      ></app-table-main>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="caseViewStatus === 1">
  <div class="row">
    <div class="col-12">
      <app-case-add [refCustomerId]="refCustomerId" (returnClicked)="caseReturn($event)"> </app-case-add>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="caseViewStatus === 2">
  <div class="row">
    <div class="col-12">
      <app-case-edit [refCaseId]="caseId" [callFromCases]="true" (returnClicked)="caseReturn($event)"> </app-case-edit>
    </div>
  </div>
</ng-template>
