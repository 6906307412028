<div *ngIf="isDataLoaded" class="bg-white">
  <div class="view form-header p-2 mt-0 mb-0 d-flex justify-content-between align-items-center">
    <div>
      <button
        (click)="return()"
        class="btn btn-default waves-light btn-sm px-3"
        title="Powrót"
        data-toggle="tooltip"
        placement="right"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <button (click)="submit()" mdbBtn size="sm" class="btn btn-default waves-light">Zapisz</button>
    </div>
    <h4>{{ 'Edycja danych użytkownika - ' + contactItem.firstName + ' ' + contactItem.lastName }}</h4>
    <div></div>
  </div>
  <form [formGroup]="contactForm">
    <div class="row">
      <div class="col-lg-7">
        <div class="card-body">
          <h5 class="mt-4 pb-2">Dane podstawowe</h5>
          <div class="row">
            <div class="col-5">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="firstName"
                  id="fFirstname"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fFirstname">Imię</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="lastName"
                  id="fLastName"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fLastName">Nazwisko</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="salutation"
                  id="fSalutation"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                />
                <label class for="fSalutation">Zwrot</label>
              </div>
            </div>
            <div class="col-9">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="jobTitle"
                  id="fJobTitle"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                />
                <label class for="fJobTitle">Tytuł zawodowy</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="email"
                  id="fEmail"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fEmail">Email / login </label>
              </div>
            </div>
            <div class="col-5">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="phone"
                  id="fPhone"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                />
                <label class for="fPhone">Telefon komórkowy</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="email2"
                  id="fEmail2"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                />
                <label class for="fEmail2">Email dodatkowy</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="phone2"
                  id="fPhone2"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                />
                <label class for="fPhone2">Telefony dodatkowe</label>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <mdb-checkbox [checked]="true" [inline]="true" formControlName="active">Aktywny</mdb-checkbox>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <mdb-checkbox [checked]="true" [inline]="true" formControlName="toOffers">Do ofert</mdb-checkbox>
              <mdb-checkbox [checked]="true" [inline]="true" formControlName="toUpdates">Do aktualizacji</mdb-checkbox>
              <mdb-checkbox [checked]="true" [inline]="true" formControlName="toNews">Do newsów</mdb-checkbox>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <mdb-checkbox [checked]="true" [inline]="true" formControlName="forMienie">iMienie</mdb-checkbox>
              <mdb-checkbox [checked]="true" [inline]="true" formControlName="forMapa">Mapa</mdb-checkbox>
              <mdb-checkbox [checked]="true" [inline]="true" formControlName="forOsrodek">Osrodek</mdb-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-body">
          <h5 class="my-4 pb-2">Uprawnienia</h5>
          <div class="row">
            <div class="col-12">
              <button
                *ngIf="showChangePassword && user.contactRights.administrator"
                class="btn btn-default waves-light btn-sm ml-0"
                (click)="editPasswordModal.show()"
              >
                Zmień hasło
              </button>
              <button
                *ngIf="showChangePassword && user.contactRights.administrator"
                class="btn btn-default waves-light btn-sm ml-0"
                (click)="resetPassword()"
              >
                Resetuj hasło
              </button>
              <button
                *ngIf="showChangePassword && user.contactRights.administrator"
                class="btn btn-default waves-light btn-sm ml-0"
                (click)="invitteClick()"
              >
                Wyślij zaproszenie
              </button>
            </div>
          </div>
          <ul class="text-left list-unstyled">
            <li class="mb-2">
              <mdb-checkbox
                #cpAccessCtrl
                [checked]="true"
                [disabled]="!user.contactRights.administrator"
                formControlName="cpAccess"
                >Dostęp do Panelu Klienta
              </mdb-checkbox>
            </li>
            <li class="mb-2" *ngIf="cpAccessCtrl.checked">
              <mdb-checkbox
                [checked]="true"
                [disabled]="!user.contactRights.administrator"
                formControlName="cpCaseAccess"
                >Zakładanie spraw
              </mdb-checkbox>
            </li>
            <li class="mb-2" *ngIf="cpAccessCtrl.checked">
              <mdb-checkbox [checked]="true" formControlName="cpUpdateAccess">Pobieranie aktualizacji</mdb-checkbox>
            </li>
            <li class="mb-2" *ngIf="cpAccessCtrl.checked">
              <mdb-checkbox [checked]="true" formControlName="cpAdmin">Administrator Panelu Klienta</mdb-checkbox>
            </li>
            <li class="mb-2" *ngIf="cpAccessCtrl.checked">
              <mdb-checkbox [checked]="true" formControlName="cpReportAccess">Pobieranie raportów</mdb-checkbox>
            </li>
            <li class="mb-2" *ngIf="cpAccessCtrl.checked">
              <mdb-checkbox [checked]="true" formControlName="cpServiceAccess">Dostęp do usług</mdb-checkbox>
            </li>
          </ul>
        </div>
        <div class="card-body" *ngIf="cpAccessCtrl.checked">
          <h5 class="my-4 pb-2">Powiadomienia</h5>
          <div class="row" formGroupName="caseNotifications">
            <div class="col-md-6">
              <div class="">
                <h6 class="my-1 pb-2">Moje sprawy</h6>
                <ul class="text-left list-unstyled">
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="ownAdded">Dodanie</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="ownAssigned">Przypisanie</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="ownCommentAdded">Dodanie komentarza</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="ownClosingProposal"
                      >Propozycja zamknięcią
                    </mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="ownClosed">Zamknięcie</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="ownAutoClose">Automatyczne zamknięcie</mdb-checkbox>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="">
                <h6 class="my-1 pb-2">Wszystkie sprawy</h6>
                <ul class="text-left list-unstyled">
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="added">Dodanie</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="assigned">Przypisanie</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="commentAdded">Dodanie komentarza</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="closingProposal"
                      >Propozycja zamknięcią
                    </mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="closed">Zamknięcie</mdb-checkbox>
                  </li>
                  <li class="mb-2">
                    <mdb-checkbox [checked]="true" formControlName="autoClose">Automatyczne zamknięcie</mdb-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!--zmiana hasła-->
  <div
    mdbModal
    #editPasswordModal="mdb-modal"
    class="modal fade"
    id="fullHeightModalRight"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog cascading-modal" role="document">
      <div class="modal-content">
        <div class="modal-header light-blue darken-3 white-text">
          <h5 class="modal-title w-100" id="myModalLabel">Edycja hasła</h5>
          <button
            type="button"
            class="close white-text"
            data-dismiss="modal"
            aria-label="Close"
            (click)="editPasswordModal.hide()"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="passwordForm">
            <div class="row">
              <div class="col-12">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="md-form mb-2">
                        <input
                          class="form-control form-control-sm"
                          formControlName="newPassword"
                          id="fNewPassword"
                          mdbInput
                          mdbValidate
                          type="password"
                          [validateSuccess]="false"
                          data-error="Wypełnij pole"
                        />
                        <label class for="fNewPassword">Nowe hasło</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="md-form mb-2">
                        <input
                          class="form-control form-control-sm"
                          formControlName="newPassword2"
                          id="fNewPassword2"
                          mdbInput
                          mdbValidate
                          type="password"
                          [validateSuccess]="false"
                          data-error="Podane wartości hasła są różne."
                        />
                        <label class for="fNewPassword2">Powtórz hasło</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3 mb-3">
              <button
                (click)="submitPassword()"
                [disabled]="passwordForm.invalid"
                class="btn btn-default btn-md waves-light"
              >
                Zapisz
              </button>
              <button (click)="editPasswordModal.toggle()" class="btn btn-outline-default btn-md waves-light">
                Anuluj
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
