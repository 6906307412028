import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DynamicTable } from '../shared/models/table';
import { map } from 'rxjs/operators';
import { ServiceLimitItem } from './models/service-limit-item';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  private apiUrl = 'api/cp/services';
  constructor(private http: HttpClient) {}

  getServiceLimits(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/customerLimits?' + parameters).pipe(map((res) => res as DynamicTable));
  }

  getServices(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/customerServices?' + parameters).pipe(map((res) => res as DynamicTable));
  }
}
