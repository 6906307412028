<!--<div *ngIf="isDataLoaded" [ngClass]="{'card card-cascade narrower':refCriticalityId===0}">-->
<div *ngIf="isDataLoaded">
  <!--<div>-->
  <!--<div *ngIf="refCriticalityId===0; else caption"-->
  <!--class="view form-header p-2 mt-0 align-items-center">-->
  <!--<h4 class="h4-responsive">{{isAdd ? 'Dodawanie kontaktu' : 'Edycja kontaktu'}}</h4>-->
  <!--</div>-->

  <!--<ng-template #caption>-->
  <div class="view form-header p-2 mt-0 mb-0 d-flex justify-content-between align-items-center">
    <button (click)="return()" class="btn btn-default waves-light btn-sm px-3" mdbTooltip="Powrót" placement="right">
      <i class="fa fa-arrow-left"></i>
    </button>
    <h5 class="font-bold">Rejestracja sprawy</h5>
    <div></div>
  </div>
  <!--</ng-template>-->
  <form [formGroup]="caseForm">
    <div class="row">
      <div class="col-lg-8">
        <div class="card-body">
          <h5 class="mt-4 pb-2">Dane zgłoszenia</h5>
          <div class="row">
            <div class="col-12 mt-4">
              <mdb-select
                formControlName="productId"
                mdbValidate
                [validateSuccess]="false"
                [options]="productSelect"
                placeholder="Wybierz"
                label="Produkt"
              ></mdb-select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-4">
              <mdb-select
                formControlName="typeId"
                mdbValidate
                [validateSuccess]="false"
                [options]="typeSelect"
                placeholder="Wybierz"
                label="Rodzaj zgłoszenia"
                (ngModelChange)="getSelectedTypeValue($event)"
              ></mdb-select>
            </div>
            <div class="col-lg-6 mt-4">
              <mdb-select
                formControlName="criticalityId"
                mdbValidate
                [validateSuccess]="false"
                [options]="criticalitySelect"
                placeholder="Wybierz"
                label="Poziom krytyczności"
              ></mdb-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="title"
                  id="fTitle"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fTitle">Tytuł</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <textarea
                  class="md-textarea"
                  style="width: 100%"
                  id="fComment"
                  type="text"
                  formControlName="comment"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                ></textarea>
                <label for="fComment">Komentarz</label>
              </div>
              <h6 style="white-space: pre-wrap; color: darkgrey">
                <i
                  >Zgłoszenie powinno zawierać opis pojedynczego problemu / zagadnienia. W przypadku wielu problemów /
                  zagadnień należy je zarejestrować jako kolejne zgłoszenia. Opis zgłoszenia powinien jednoznacznie i
                  bardzo szczegółowo określać czego dotyczy dane zgłoszenie. Prosimy w opisie zawrzeć informacje: datę i
                  godzinę wystąpienia problemu, login użytkownika oraz jakie należy wykonać kroki, by odtworzyć
                  zgłaszany błąd. Najlepiej opis dojścia do błędu ubrać w listę punktowaną z sekwencją kroków do
                  wykonania. Zastosowanie się do powyższych zasad umożliwi poprawną realizację zgłoszenia
                </i>
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="contactName"
                  id="fContactName"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fContactName">Zgłaszający</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="contactEmail"
                  id="fContactEmail"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fContactEmail">Email</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="contactPhone"
                  id="fContactPhone"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fContactPhone">Telefon</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  formControlName="clientNumber"
                  id="fClientNumber"
                  mdbInput
                  mdbValidate
                  type="text"
                  [validateSuccess]="false"
                  data-error="Wypełnij pole"
                />
                <label class for="fClientNumber">Identyfikator zgłoszenia w systemie zewnętrznym</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-body h-100">
          <h5 class="my-4 pb-2">Załączniki ({{ attachmentList.length }})</h5>
          <div class="row>">
            <div class="col-12">
              <app-file-uploader (uploadedFile)="addAttachment($event)"></app-file-uploader>
            </div>
          </div>
          <div class="row mt-4" *ngIf="attachmentList.length > 0">
            <div class="col-12">
              <div style="max-height: 250px; overflow-y: auto">
                <div *ngFor="let f of attachmentList" class="mt-1">
                  <span>{{ f.fileName }}</span>
                  <span class="mr-2" style="float: right; cursor: pointer" (click)="removeAttachment(f)"
                    ><i class="fa fa-times pink-text"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5 mb-3">
      <button (click)="clickSubmit()" class="btn btn-default waves-light" mdbRippleRadius>Zapisz</button>
      <button (click)="Cancel()" class="btn btn-outline-default waves-light" mdbRippleRadius>Anuluj</button>
    </div>
  </form>
</div>
