import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LicencesService } from './licences.service';
import { DynamicTable } from '../shared/models/table';

@Injectable()
export class LicencesResolve implements Resolve<DynamicTable> {
  constructor(private licenceService: LicencesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.licenceService.getAll('');
  }
}
