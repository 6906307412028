import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-skin',
  templateUrl: './skin.component.html',
  styleUrls: ['./skin.component.scss'],
})
export class SkinComponent implements OnInit {
  actualSkin = 'mdb-skin';
  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  changeSkin(skin: string) {
    this.renderer.removeClass(document.body, this.actualSkin);
    this.renderer.addClass(document.body, skin);
    this.actualSkin = skin;
  }
}
