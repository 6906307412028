<div *ngIf="isDataLoaded" class="row">
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info" style="max-width: 20rem">
    <mdb-card-header class="info-color">Wersja Mapa</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">{{ softwareMainVersionItem.versionMapa }}</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info" style="max-width: 20rem">
    <mdb-card-header class="info-color">Wersja Ośrodek</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">{{ softwareMainVersionItem.versionOsrodek }}</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info" style="max-width: 20rem">
    <mdb-card-header class="info-color">Data weryfikacji</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">{{ softwareMainVersionItem.versionCheckDate }}</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info" style="max-width: 20rem">
    <mdb-card-header class="info-color">Architectura</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">{{ softwareMainVersionItem.architecture }}</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>

  <div class="ml-2" style="display: block; max-width: 13rem">
    <button
      mdbBtn
      class="btn btn-default btn-sm waves-light mt-auto"
      style="margin-bottom: 13px; width: 100%"
      (click)="refreshClick()"
    >
      {{ buttonMessage }}
    </button>
    <button
      mdbBtn
      class="btn btn-danger btn-sm waves-light mt-auto"
      style="margin-bottom: 13px; width: 100%"
      (click)="clickUpdateManual()"
    >
      Aktualizuj aplikacje
    </button>
    <button
      mdbBtn
      class="btn btn-default btn-sm waves-light mt-auto"
      style="margin-bottom: 13px; width: 100%"
      (click)="clickShowProgress()"
    >
      Postęp aktualizacji
    </button>
    <!--    <button mdbBtn class="btn btn-default btn-sm waves-light mt-auto" style="width: 100%" (click)="clickDownloadLog()">-->
    <!--      Pobirz log-->
    <!--    </button>-->
  </div>
</div>
<div *ngIf="!isDataLoaded" class="row">
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info">
    <mdb-card-header class="info-color">Oczekiwanie....</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">Trwa sprawdzanie zainstalowanej wersji aplikacji. Proszę czekać...</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>
</div>
