import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicTable } from '../shared/models/table';
import { SoftwareVersionInfoItem } from './models/software-version-info';
import { SoftwareMainVersionItem } from './models/software-main-version-item';
import { SoftwareUpdateManualDef } from './models/software-update-manual-def';

@Injectable({
  providedIn: 'root',
})
export class SoftwareUpdatesService {
  private apiUrl = 'api/cp/softwareUpdates/client';
  constructor(private http: HttpClient) {}

  getIsEnabled(): Observable<boolean> {
    return this.http.get(this.apiUrl + '/isEnabled').pipe(map((res) => res as boolean));
  }

  getInProgress(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/inProgress?' + parameters).pipe(map((res) => res as DynamicTable));
  }

  getHistory(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/history?' + parameters).pipe(map((res) => res as DynamicTable));
  }

  getAvailable(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/getAvailable?' + parameters).pipe(map((res) => res as DynamicTable));
  }

  getSoftwareMainVersion(forceRefresh: boolean): Observable<SoftwareMainVersionItem> {
    return this.http
      .get(this.apiUrl + `/softwareMainVersion/` + (forceRefresh ? '?forceRefresh=true' : ''))
      .pipe(map((res) => res as SoftwareMainVersionItem));
  }

  getForManualUpdate(): Observable<SoftwareUpdateManualDef> {
    return this.http.get(this.apiUrl + `/definition/getForManual`).pipe(map((res) => res as SoftwareUpdateManualDef));
  }

  setManualUpdate(update: SoftwareUpdateManualDef) {
    return this.http.post(this.apiUrl + `/setManualUpdate`, update);
  }

  getActiveUpdatesGuids(): Observable<string[]> {
    return this.http.get(this.apiUrl + `/activeUpdates`).pipe(map((res) => res as string[]));
  }

  checkAppVersion(updateId: number, forceSaveVersion: boolean): Observable<SoftwareVersionInfoItem> {
    return this.http
      .get(this.apiUrl + `/checkAppVersion/${updateId}/${forceSaveVersion}`)
      .pipe(map((res) => res as SoftwareVersionInfoItem));
  }

  tryAgain(updateId: number) {
    return this.http.get(this.apiUrl + `/tryAgain/${updateId}`);
  }
}
