import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { ToolsService } from '../../../../shared/services/tools.service';
import { UpdatesService } from '../../../updates.service';
import { SoftwareUpdateManualDef } from '../../../models/software-update-manual-def';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-software-update-manual-dialog',
  templateUrl: './software-update-manual-dialog.component.html',
})
export class SoftwareUpdateManualDialogComponent implements OnInit {
  refCustomerId: number;
  definitionForm: UntypedFormGroup;
  action: Subject<any> = new Subject();
  softwareUpdateManualDef: SoftwareUpdateManualDef;
  isDataLoaded = false;

  constructor(
    private updatesService: UpdatesService,
    private toolsService: ToolsService,
    private modalRef: MDBModalRef,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    forkJoin([this.updatesService.getForManualUpdate(this.refCustomerId)]).subscribe((results) => {
      this.softwareUpdateManualDef = results[0];
      this.softwareUpdateManualDef.updateDefs.forEach(
        (item) => (item.active = !item.isCurrentVersion && item.subscriptionActive),
      );
      this.definitionForm = this.buildConfirmForm();
      this.isDataLoaded = true;
    });
  }

  buildConfirmForm() {
    return this.formBuilder.group({
      date: [this.softwareUpdateManualDef.date, Validators.required],
      time: [this.softwareUpdateManualDef.time, Validators.required],
    });
  }

  onYesClick() {
    if (this.definitionForm.valid) {
      this.softwareUpdateManualDef.date = this.definitionForm.controls.date.value;
      this.softwareUpdateManualDef.time = this.definitionForm.controls.time.value;
      this.action.next({ status: true, definitionItem: this.softwareUpdateManualDef });
    } else {
      this.toolsService.validateAllFormFields(this.definitionForm);
    }
  }

  onNoClick() {
    this.action.next({ status: false });
  }

  setAll(event) {
    this.softwareUpdateManualDef.updateDefs.forEach((item) => (item.active = event.checked));
  }

  onChangeSelectAll($event) {
    this.softwareUpdateManualDef.updateDefs
      .filter((item) => item.subscriptionActive)
      .forEach((item) => (item.active = $event.checked));
  }
}
