import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CaseResolve, ProfileResolve, TrainingResolve } from './dashboard-resolve.service';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    // CardsModule.forRoot(),
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    // BadgeModule
  ],
  exports: [DashboardComponent],
  providers: [ProfileResolve, CaseResolve, TrainingResolve],
})
export class DashboardModule {}
