import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicTable } from '../shared/models/table';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LicencesService {
  private apiUrl = 'api/cp/licences';
  constructor(private http: HttpClient) {}

  getAll(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/customer?' + parameters).pipe(map((res) => res as DynamicTable));
  }

  getUpdatesByLicenceId(licenceId: number): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + `/customerUpdates/${licenceId}`).pipe(map((res) => res as DynamicTable));
  }
}
