import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoftwareUpdateFullViewComponent } from './software-update-full-view/software-update-full-view.component';
import { SoftwareMainVersionComponent } from './software-main-version/software-main-version.component';
import { SoftwareUpdateVersionCheckDialogComponent } from './modals/software-update-version-check-dialog/software-update-version-check-dialog.component';
import { SoftwareUpdateProgressDialogComponent } from './modals/software-update-progress-dialog/software-update-progress-dialog.component';
import { SoftwareUpdateShowComponent } from './software-update-show/software-update-show.component';
import { SoftwareUpdateHistoryComponent } from './software-update-history/software-update-history.component';
import { SharedModule } from '../shared/shared.module';
import { CardsModule, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { SoftwareUpdateManualDialogComponent } from './modals/software-update-manual-dialog/software-update-manual-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SoftwareUpdateVersionCheckComponent } from './modals/software-update-version-check/software-update-version-check.component';
import { SoftwareUpdatesStatusService } from './software-updates-status.service';

@NgModule({
  declarations: [
    SoftwareUpdateFullViewComponent,
    SoftwareMainVersionComponent,
    SoftwareUpdateVersionCheckDialogComponent,
    SoftwareUpdateProgressDialogComponent,
    SoftwareUpdateShowComponent,
    SoftwareUpdateHistoryComponent,
    SoftwareUpdateManualDialogComponent,
    SoftwareUpdateVersionCheckComponent,
  ],
  imports: [CommonModule, SharedModule, CardsModule, MDBBootstrapModulesPro, ReactiveFormsModule, FormsModule],
  providers: [SoftwareUpdatesStatusService],
})
export class SoftwareUpdatesModule {}
