import { Component, OnInit } from '@angular/core';
import { LayoutService } from './modules/shared/services/layout.service';
import { AuthService } from './modules/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'crm';
  isMainHeaderVisible = false;

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    // this.layoutService.mainHeaderBar$.subscribe((isVisible) => {
    //   this.isMainHeaderVisible = isVisible;
    // });
    // this.router.events.subscribe((routerEvent: Event) => this.checkRouterEvent(routerEvent));
    //this.router.navigate(['/panel']);
  }

  isHeaderEnable() {
    return this.authService.isLoggedIn();
  }
}
