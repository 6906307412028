import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';
import { NgModule } from '@angular/core';
import { SoftwareUpdateFullViewComponent } from './software-update-full-view/software-update-full-view.component';

const SOFTWAREUPDATES: Route[] = [
  {
    path: 'aktualizacje-oprogramowania',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>SoftwareUpdateFullViewComponent,
      },
      // {
      //   path: ':id',
      //   component: <any>SoftwareUpdateShowComponent,
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(SOFTWAREUPDATES)],
  exports: [RouterModule],
})
export class SoftwareUpdatesRoutingModule {}
