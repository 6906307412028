import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageItem } from '../../models/message-item';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesService } from '../../messages.service';
import { forkJoin } from 'rxjs';
import { FilesService } from '../../../files/files.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-message-show',
  templateUrl: './message-show.component.html',
})
export class MessageShowComponent implements OnInit {
  @Input() refMessageId = 0;
  @Output() returnClicked = new EventEmitter<any>();
  private messageItem: MessageItem;
  isDataLoaded = false;
  viewState = 0;
  htmlContent: any;

  constructor(
    private messagesService: MessagesService,
    private filesService: FilesService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    if (this.route.routeConfig.path !== 'mg/:id') {
      if (!this.refMessageId) {
        this.refMessageId = this.route.snapshot.params.id;
      }
      forkJoin([this.messagesService.getMessageById(this.refMessageId)]).subscribe((results) => {
        this.messageItem = results[0];
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.messageItem.content);
        this.isDataLoaded = true;
      });
    } else {
      forkJoin([this.messagesService.getMessageByMailingActionId(this.route.snapshot.params.id)]).subscribe(
        (results) => {
          this.messageItem = results[0];
          this.refMessageId = this.messageItem.id;
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.messageItem.content);
          this.isDataLoaded = true;
        },
      );
    }
  }

  return() {
    if (this.returnClicked.observers?.length) {
      this.returnClicked.emit();
    } else {
      this.router.navigate(['wiadomosci']);
    }
  }

  private clickDownloadFile(id: number) {
    const emailHistoryId = this.messageItem.documents.find((el) => el.fileId === id).id;
    this.filesService.downloadMailAttachment(emailHistoryId, id);
  }
}
