<div class="row">
  <div class="col-12">
    <!--    <ng-template [ngIf]="contactViewStatus===0">-->
    <app-table-main
      *ngIf="contactsTable"
      (reload)="reload()"
      tableCaption="Osoby kontaktowe"
      [dynamicTable]="contactsTable"
      [butAddVisible]="true"
      [butExportVisible]="false"
      (addClicked)="add()"
      (selectedIdChange)="edit($event)"
    >
    </app-table-main>
  </div>
</div>
