import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { SoftwareUpdatesService } from '../software-updates.service';

@Component({
  selector: 'app-software-update-full-view',
  templateUrl: './software-update-full-view.component.html',
})
export class SoftwareUpdateFullViewComponent implements OnInit {
  @Input() refCustomerId = 0;
  viewType = 'typeUpdate';
  isDataLoaded = false;

  constructor(private softwareUpdatesService: SoftwareUpdatesService) {}

  ngOnInit(): void {
    // forkJoin([this.softwareUpdatesService.getConfigItemById(this.refCustomerId)]).subscribe((results) => {
    //   this.softwareUpdateConfigItem = results[0];
    //   if (!this.refCustomerId) {
    //     this.viewType = 'typeUpdate';
    //   } else {
    //     if (this.softwareUpdateConfigItem.id > 0) {
    //       this.viewType = 'typeUpdate';
    //     } else {
    //       this.viewType = 'typeConfig';
    //     }
    //   }
    this.isDataLoaded = true;
    // });
  }
}
