import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Credentials } from '../../models/credentials';
import { ToastService } from 'ng-uikit-pro-standard';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  returnUrl: string;
  credentials: Credentials;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.loginForm = this.buildLoginForm();
    // this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  buildLoginForm() {
    return this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmmit() {
    // this.authService.login(this.login, this.password.ts)
    //   .then(this.onSubmitSuccess.bind(this), this.onSubmitFailure);
    this.credentials = Object.assign({}, this.loginForm.value);
    this.authService.login(this.credentials.login, this.credentials.password).subscribe(
      (data) => {
        this.onSubmitSuccess();
      },
      (error) => {
        this.onSubmitFailure(error);
      },
    );
  }

  private onSubmitSuccess() {
    if (!this.returnUrl || this.returnUrl === '/') {
      this.returnUrl = '/panel';
    }
    this.router.navigate([this.returnUrl]).then(() => {
      // this.layoutService.showMainHeader();
    });
  }

  private onSubmitFailure(error) {
    const options = {
      closeButton: true,
      tapToDismiss: false,
      positionClass: 'md-toast-bottom-full-width',
      progressBar: true,
    };
    this.toastService.error(error, 'Błąd', options);
  }
}
