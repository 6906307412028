import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterCache, TableHead } from '../../models/table';
import { TableSettingsService } from '../../services/table-settings.service';

@Component({
  selector: 'tr[app-table-filter-row]',
  templateUrl: './table-filter-row.component.html',
})
export class TableFilterRowComponent implements OnInit {
  @Input() code: string;
  @Input() tableHeaders: TableHead[];
  @Input() multiSelect = false;
  @Output() reload = new EventEmitter<any>();
  @Output() setIsFilter = new EventEmitter<any>();
  isFilter: boolean;
  checkboxOptions = [
    { value: '', label: '' },
    { value: 'true', label: 'Tak' },
    { value: 'false', label: 'Nie' },
  ];

  constructor(private tableService: TableSettingsService) {}

  ngOnInit(): void {
    // this.tableHeaders.forEach(item => {
    //   if (item.type === 8) {
    //     if (item.filterValue) {
    //       item.filterValue = item.filterValue.split(',');
    //     }
    //   }
    // });
  }

  filterChange() {
    const filters: FilterCache[] = [];
    this.tableHeaders.forEach((item) => {
      if (item.filterValue) {
        filters.push({ filterField: item.filterField, filterValue: item.filterValue });
      }
    });
    this.tableService.setFiltering(this.code, filters);
    this.tableService.setPage(this.code, '1');
    this.reload.emit(this.tableHeaders.some((item) => !!item.filterValue));
  }

  getSelectedValue(value, tableHead: TableHead) {
    tableHead.filterValue = value;
    this.filterChange();
  }
}
