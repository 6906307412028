<!--<app-software-main-version (refreshClicked)="reload()"></app-software-main-version>-->
<div class="row">
  <div class="col-12">
    <app-table-main
      *ngIf="updatesTable"
      (reload)="reload()"
      (selectedIdChange)="clickDetails($event)"
      tableCaption="Aktualizacje w toku"
      [dynamicTable]="updatesTable"
    >
    </app-table-main>
  </div>
</div>
<!--<ng-template #licencePreview>-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <app-licence-add-edit-->
<!--        [refLicenceId]="licenceId"-->
<!--        (returnClicked)="licenceReturn($event)"-->
<!--        [isAdd]="licenceId === -1 ? true : false"-->
<!--      >-->
<!--      </app-licence-add-edit>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
