import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactAddComponent } from './components/contact-add/contact-add.component';
import { ContactEditComponent } from './components/contact-edit/contact-edit.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { ContactsResolve } from './administration-resolve.service';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InputUtilitiesModule, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

@NgModule({
  declarations: [ContactAddComponent, ContactEditComponent, ContactListComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, InputUtilitiesModule, MDBBootstrapModulesPro],
  providers: [ContactsResolve],
})
export class AdministrationModule {}
