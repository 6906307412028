import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ServicesService } from './services.service';
import { DynamicTable } from '../shared/models/table';

@Injectable()
export class ServiceLimitsResolve implements Resolve<DynamicTable> {
  constructor(private servicesService: ServicesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.servicesService.getServiceLimits('');
  }
}

@Injectable()
export class ServicesResolve implements Resolve<DynamicTable> {
  constructor(private servicesService: ServicesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.servicesService.getServices('');
  }
}
