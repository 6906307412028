import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { ConfirmDialogComponent } from '../modals/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  modalRef: MDBModalRef;

  constructor(
    private toastService: ToastService,
    private modalService: MDBModalService,
  ) {}

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
    this.toastService.error('Nieprawidłowo wypełniony formularz.', 'Błąd');
  }

  showConfirmation(title: string, subtitle: string, type = 'danger') {
    const subject = new Subject<boolean>();
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: `cascading-modal modal-notify modal-${type}`,
      containerClass: 'w-100',
      animated: true,
      data: {
        heading: title,
        content: {
          heading: subtitle,
        },
        btnColor: type,
      },
    };
    this.modalRef = this.modalService.show(ConfirmDialogComponent, modalOptions);
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
      subject.next(result.status);
    });
    return subject.asObservable();
  }
}
