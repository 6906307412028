<header>
  <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg navbar-dark" [containerInside]="false">
    <mdb-navbar-brand>
      <a class="navbar-brand" (click)="goToDashboard()">
        <img
          src="../../../assets/images/logo%20panel%20klienta.png"
          height="30"
          class="d-inline-block align-top"
          alt=""
      /></a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [ngClass]="{ active: layoutService.activeElement === 'case' }">
          <a class="nav-link waves-light" (click)="goToCases()" mdbWavesEffect
            >Sprawy<span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item" [ngClass]="{ active: layoutService.activeElement === 'documentation' }">
          <a class="nav-link waves-light" (click)="goToDocumentation()" mdbWavesEffect>Dokumentacje</a>
        </li>
        <li
          *ngIf="user.contactRights.updateDownload"
          class="nav-item"
          [ngClass]="{ active: layoutService.activeElement === 'licence' }"
        >
          <a class="nav-link waves-light" (click)="goToLicences()" mdbWavesEffect>Licencje</a>
        </li>
        <li
          *ngIf="user.contactRights.service"
          class="nav-item"
          [ngClass]="{ active: layoutService.activeElement === 'services' }"
        >
          <a class="nav-link waves-light" (click)="goToServices()" mdbWavesEffect>Usługi</a>
        </li>
        <li
          *ngIf="user.contactRights.administrator"
          class="nav-item"
          [ngClass]="{ active: layoutService.activeElement === 'updates' }"
        >
          <a class="nav-link waves-light" (click)="goToUpdates()" mdbWavesEffect>Aktualizacje</a>
        </li>
        <li class="nav-item" [ngClass]="{ active: layoutService.activeElement === 'messages' }">
          <a class="nav-link waves-light" (click)="goToMessages()" mdbWavesEffect>Wiadomości</a>
        </li>
        <li
          *ngIf="user.contactRights.administrator"
          class="nav-item"
          [ngClass]="{ active: layoutService.activeElement === 'administration' }"
        >
          <a class="nav-link waves-light" (click)="goToAdministration()" mdbWavesEffect>Administracja</a>
        </li>
      </ul>

      <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav ie-hidden-double-nav">
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            <i class="fa fa-user"></i> {{ userName }}<span class="caret"></span
          ></a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
            <a class="dropdown-item" (click)="settings()"
              ><span class="clearfix d-none d-sm-inline-block">Ustawienia</span></a
            >
            <a class="dropdown-item" (click)="changePassword()"
              ><span class="clearfix d-none d-sm-inline-block">Zmień hasło</span></a
            >
            <a class="dropdown-item" (click)="logout()"
              ><span class="clearfix d-none d-sm-inline-block">Wyloguj</span></a
            >
          </div>
        </li>
      </ul>
    </links>
  </mdb-navbar>
</header>
