<div class="row">
  <div class="col-12">
    <app-table-main
      *ngIf="serviceLimitTable"
      tableCaption="Kwoty do wykorzystania na usługi serwisowe"
      (reload)="serviceLimitsReload()"
      [dynamicTable]="serviceLimitTable"
    >
    </app-table-main>
  </div>
</div>
