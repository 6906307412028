import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showColumn',
})
export class ShowColumnPipe implements PipeTransform {
  transform(objects: any[]): any[] {
    if (objects) {
      return objects.filter((object) => {
        // return object.width.substr(0, object.width.length - 2) > 0;
        return object.show;
      });
    }
  }
}
