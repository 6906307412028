<div [ngClass]="{ card: refCaseId === 0 }" *ngIf="isDataLoaded">
  <div class="view form-header p-2 mt-0 mb-0 d-flex justify-content-between align-items-center">
    <button (click)="return()" class="btn btn-default waves-light btn-sm px-3" mdbTooltip="Powrót" placement="right">
      <i class="fa fa-arrow-left"></i>
    </button>
    <h3>Szczegóły sprawy - {{ caseItem.caseNumber }}</h3>
    <div></div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="card-body">
        <h5 class="mt-4 pb-2">Dane zgłoszenia</h5>
        <div class="row">
          <div class="col-12" *ngIf="caseItem.active">
            <button
              class="btn btn-default waves-light btn-sm ml-0"
              (click)="setClosingMode(0); confirmationForm.show()"
              mdbRippleRadius
            >
              Zamknij
            </button>
            <button
              class="btn btn-danger waves-light btn-sm ml-0"
              (click)="setClosingMode(1); confirmationForm.show()"
              mdbRippleRadius
            >
              Anuluj
            </button>
            <button
              class="btn waves-light btn-sm pull-right"
              [ngClass]="caseItem.assignedTo.id === 0 ? 'btn-danger' : 'btn-default'"
              (click)="contactSI.toggle()"
              mdbWavesEffect
            >
              {{
                caseItem.assignedTo.id === 0
                  ? 'Nieprzypisana'
                  : caseItem.assignedTo.firstName + ' ' + caseItem.assignedTo.lastName
              }}
            </button>
            <button class="btn btn-default waves-light btn-sm ml-0" (click)="showEditModal()" mdbWavesEffect>
              Edycja
            </button>
          </div>
          <div class="col-12" mdbCollapse [isCollapsed]="true" #contactSI="bs-collapse">
            <div class="row text-center" *ngIf="caseItem.assignedTo.id > 0">
              <div class="col-md-4">
                <a mdbBtn floating="true" color="blue" class="accent-1 waves-light" mdbWavesEffect>
                  <i class="fa fa-user"></i>
                </a>
                <p>{{ caseItem.assignedTo.firstName }} {{ caseItem.assignedTo.lastName }}</p>
              </div>
              <div class="col-md-4">
                <a
                  mdbBtn
                  floating="true"
                  color="blue"
                  class="accent-1 waves-light"
                  mdbWavesEffect
                  href="tel:{{ caseItem.assignedTo.phone }}"
                >
                  <i class="fa fa-phone"></i>
                </a>
                <p>{{ caseItem.assignedTo.phone }}</p>
              </div>
              <div class="col-md-4">
                <a
                  mdbBtn
                  floating="true"
                  color="blue"
                  class="accent-1 waves-light"
                  mdbWavesEffect
                  href="mailto:{{ caseItem.assignedTo.email }}"
                >
                  <i class="fa fa-envelope"></i>
                </a>
                <p>{{ caseItem.assignedTo.email }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ statusSelect | listText: caseItem.statusId }}"
                id="fStatus"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fStatus">Status sprawy</label>
            </div>
          </div>
          <div *ngIf="caseItem.active" class="col-md-4">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.estimatedDate }}"
                id="fEstimatedDate"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fEstimatedDate">Przewidywany termin rozwiązania</label>
            </div>
          </div>
          <div *ngIf="!caseItem.active" class="col-md-4">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.modificationDate }}"
                id="fClosedDate"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fClosedDate">Zamknięta dnia</label>
            </div>
          </div>
          <div *ngIf="!caseItem.active" class="col-md-4">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.modifiedBy.firstName }} {{ caseItem.modifiedBy.lastName }}"
                id="fClosedBy"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fClosedBy">Zamknięta przez</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ criticalitySelectFull | listText: caseItem.criticalityId }}"
                id="fCriticality"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fCriticality">Poziom krytyczności</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.creationDate }}"
                id="fCreationDate"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fCreationDate">Data zgłoszenia</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ typeSelect | listText: caseItem.requestTypeId }}"
                id="fType"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fType">Rodzaj zgłoszenia</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ productSelect | listText: caseItem.productId }}"
                id="fTitle2"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fTitle2">Produkt</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.title }}"
                id="fTitle1"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fTitle1">Tytuł</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.creatorName }}"
                id="fCreatedBy"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fCreatedBy">Osoba zgłaszająca</label>
            </div>
          </div>
          <div class="col-md-5">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.creatorEmail }}"
                id="fCreEmail"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fCreEmail">Email</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.creatorPhone }}"
                id="fCrePhone"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fCrePhone">Telefon</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3">
            <div class="md-form mb-2">
              <input
                class="form-control form-control-sm"
                value="{{ sourceSelect | listText: caseItem.sourceId }}"
                id="fSource"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fSource">Źródło</label>
            </div>
          </div>
          <div class="col-xl-9">
            <div *ngIf="!isEdit" class="md-form input-group">
              <input
                class="form-control form-control-sm"
                value="{{ caseItem.clientNumber }}"
                id="fclientNumberE"
                mdbInput
                mdbValidate
                type="text"
                disabled
              />
              <label class for="fclientNumberE">Identyfikator zgłoszenia w systemie zewnętrznym</label>
              <div class="input-group-append" id="button-addon4">
                <button
                  *ngIf="caseItem.clientNumber"
                  class="m-0 px-3 btn btn-default btn-sm"
                  type="button"
                  mdbWavesEffect
                  (click)="copyText(caseItem.clientNumber)"
                  mdbTooltip="Kopiuj"
                >
                  <i class="fa fa-copy"></i>
                </button>
                <button
                  *ngIf="caseItem.clientNumber.startsWith('http')"
                  class="m-0 px-3 btn btn-default btn-sm"
                  type="button"
                  mdbWavesEffect
                  (click)="goToLink(caseItem.clientNumber)"
                  mdbTooltip="Otwórz link"
                >
                  <i class="fa fa-link"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Komentarze-->
    <div class="col-lg-6">
      <div class="card-body">
        <h5 class="mt-4 pb-2">Komentarze</h5>

        <div class="row mb-3">
          <div class="col-12">
            <app-case-comment
              *ngIf="addingComment"
              [caseId]="caseItem?.id"
              class="mb-4"
              (save)="saveComment()"
              (cancel)="cancelComment()"
              [receiver]="caseItem?.createdBy.firstName + ' ' + caseItem?.createdBy.lastName"
              [receiverEmail]="caseItem?.createdBy.email"
              [title]="'[' + caseItem.caseNumber + '] - ' + caseItem.title"
            ></app-case-comment>
            <button
              *ngIf="!addingComment && caseItem?.active && (statusSelect | listCode: caseItem.statusId) != 4"
              class="btn btn-default waves-light btn-sm ml-0"
              (click)="addComment()"
              mdbRippleRadius
            >
              Dodaj komentarz
            </button>
            <button
              *ngIf="!addingComment && (statusSelect | listCode: caseItem.statusId) == 4 && caseItem?.active"
              class="btn btn-default waves-light btn-sm ml-0"
              (click)="addComment('4')"
              mdbRippleRadius
            >
              Wznów sprawę
            </button>
          </div>
        </div>
        <!--<div class="grey lighten-3 chat-room p-3 round">-->
        <div class="pt-2">
          <ul class="list-unstyled chat">
            <li class="d-flex mb-4" *ngFor="let comment of comments">
              <div *ngIf="comment.source === 1" class="mr-1">
                <span class="fa fa-2x fa-reply fa-flip-horizontal" [ngClass]="'color' + comment.source"></span>
              </div>
              <mdb-card class="mb-2 w-100">
                <mdb-card-header class="bg-white">
                  <span
                    class="fa pull-left mt-1 orange-text mr-2"
                    [ngClass]="{
                      'fa-comment': comment.type === 0,
                      'fa-envelope': comment.type === 1,
                      'fa-phone': comment.type === 2,
                    }"
                  ></span>
                  <strong class="primary-font light-blue-text">{{ comment.createdByName }}</strong>
                  <small class="float-right text-muted"
                    ><i class="fa fa-clock"></i> {{ comment.creationDate | date: 'medium' : '' : 'pl-PL' }}</small
                  >
                </mdb-card-header>
                <mdb-card-body>
                  <mdb-card-text *ngIf="comment.type === 1"
                    >Adresat: {{ comment.receiver }} ; {{ comment.receiverEmail }}</mdb-card-text
                  >
                  <mdb-card-title *ngIf="comment.type === 1">{{ comment.title }}</mdb-card-title>
                  <mdb-card-text style="white-space: pre-wrap">{{ comment.comment }}</mdb-card-text>
                </mdb-card-body>
                <mdb-card-footer>
                  <span
                    *ngIf="comment?.attachmentsCount && comment.attachmentsCount > 0"
                    (click)="switchAttachment(comment.id)"
                    class="badge badge-secondary"
                  >
                    <i class="fa fa-paperclip fa-fw"></i>
                    {{ comment.attachmentsCount }}
                  </span>
                  <a
                    class="like pull-right"
                    *ngIf="comment.contactName !== comment.createdByName && comment.contactPhone"
                    ><span><i class="fa fa-phone mx-2"></i>{{ comment.contactPhone }}</span></a
                  >
                  <a class="like pull-right" *ngIf="comment.contactName !== comment.createdByName"
                    ><span><i class="fa fa-user mx-2"></i>{{ comment.contactName }}</span></a
                  >
                  <div *ngIf="comment.showAttachments" class="attachment">
                    <app-attachment [attachments]="comment.attachmentList"></app-attachment>
                  </div>
                </mdb-card-footer>
              </mdb-card>
              <div *ngIf="comment.source === 0" class="ml-1">
                <span class="fa fa-2x fa-reply" [ngClass]="'color' + comment.source"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!--Potwierdzenie-->

  <div
    mdbModal
    #confirmationForm="mdb-modal"
    class="modal fade"
    id="modalLogin"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog cascading-modal" role="document">
      <div class="modal-content">
        <div class="modal-header light-blue darken-3 white-text">
          <h4 class="title">Potwierdzenie</h4>
          <button
            type="button"
            class="close waves-effect waves-light"
            data-dismiss="modal"
            aria-label="Close"
            (click)="confirmationForm.hide()"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center mt-2">
            <span *ngIf="closingMode == 0">Czy chcesz zamknąć sprawę?</span>
            <span *ngIf="closingMode == 1">Czy chcesz anulować sprawę?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default waves-light" (click)="confirmationForm.hide(); closeCase()" mdbRippleRadius>
            Tak
          </button>
          <button
            class="btn btn-outline-default ml-auto"
            data-dismiss="modal"
            (click)="confirmationForm.hide()"
            mdbRippleRadius
          >
            Nie
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--edycja-->
  <div
    mdbModal
    #editModal="mdb-modal"
    class="modal fade"
    id="fullHeightModalRight"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog cascading-modal" role="document">
      <div class="modal-content">
        <div class="modal-header light-blue darken-3 white-text">
          <h5 class="modal-title w-100" id="myModalLabel">Edycja zgłoszenia</h5>
          <button
            type="button"
            class="close white-text"
            data-dismiss="modal"
            aria-label="Close"
            (click)="editModal.hide()"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="caseForm">
            <div class="row">
              <div class="col-12">
                <div class="card-body">
                  <div class="row">
                    <div class="col mt-4">
                      <mdb-select
                        formControlName="requestTypeId"
                        mdbValidate
                        [validateSuccess]="false"
                        [options]="typeSelect"
                        placeholder="Wybierz"
                        label="Rodzaj zgłoszenia"
                        (ngModelChange)="getSelectedTypeValue($event)"
                      ></mdb-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col mt-4">
                      <mdb-select
                        formControlName="criticalityId"
                        mdbValidate
                        [validateSuccess]="false"
                        [options]="criticalitySelect"
                        placeholder="Wybierz"
                        label="Poziom krytyczności"
                      ></mdb-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="md-form mb-2">
                        <input
                          class="form-control form-control-sm"
                          formControlName="clientNumber"
                          id="fClientNumber"
                          mdbInput
                          mdbValidate
                          type="text"
                          [validateSuccess]="false"
                          data-error="Wypełnij pole"
                        />
                        <label classFor="fClientNumber">Identyfikator zgłoszenia w systemie zewnętrznym</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-5 mb-3">
              <button (click)="saveUpdateCase()" class="btn btn-default waves-light" mdbRippleRadius>Zapisz</button>
              <button (click)="editModal.toggle()" class="btn btn-outline-default waves-light" mdbRippleRadius>
                Anuluj
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
