import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CasesRoutingModule } from './modules/cases/cases-routing.module';
import { SettingsRoutingModule } from './modules/settings/settings-routing.module';
import { DocumentationRoutingModule } from './modules/documentation/documentation-routing.module';
import { AuthRoutingModule } from './modules/auth/auth-routing.module';
import { DashboardRoutingModule } from './modules/dashboard/dashboard-routing.module';
import { TrainingsRoutingModule } from './modules/trainings/trainings-routing.module';
import { PageNotFoundComponent } from './modules/shared/components/page-not-found/page-not-found.component';
import { LicencesRoutingModule } from './modules/licences/licences-routing.module';
import { ServicesRoutingModule } from './modules/services/services-routing.module';
import { UpdatesRoutingModule } from './modules/updates/updates-routing.module';
import { MessagesRoutingModule } from './modules/messages/messages-routing.module';
import { AdministrationRoutingModule } from './modules/administration/administration-routing.module';
import {SoftwareUpdatesRoutingModule} from './modules/software-updates/software-updates-routing.module';

const APP_ROUTES: Route[] = [
  {
    path: '',
    redirectTo: '/panel',
    pathMatch: 'full',
  },
  // { path: '**', component: PageNotFoundComponent},
  {
    path: '**',
    redirectTo: '/panel',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    AdministrationRoutingModule,
    AuthRoutingModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: true }),
    CasesRoutingModule,
    DocumentationRoutingModule,
    DashboardRoutingModule,
    LicencesRoutingModule,
    MessagesRoutingModule,
    ServicesRoutingModule,
    SettingsRoutingModule,
    SoftwareUpdatesRoutingModule,
    TrainingsRoutingModule,
    UpdatesRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
