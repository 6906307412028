import { Component, OnDestroy, OnInit } from '@angular/core';
import { SoftwareUpdatesStatusService } from '../../software-updates-status.service';
import { SoftwareUpdatesService } from '../../software-updates.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { SoftwareUpdateStatusItem } from '../../models/software-update-status';
import { SoftwareUpdateVersionCheckComponent } from '../software-update-version-check/software-update-version-check.component';

@Component({
  selector: 'app-software-update-progress-dialog',
  templateUrl: './software-update-progress-dialog.component.html',
})
export class SoftwareUpdateProgressDialogComponent implements OnInit, OnDestroy {
  groupGuid: string = '';
  refCustomerId: number = 0;
  action: Subject<any> = new Subject();
  statusInfo: SoftwareUpdateStatusItem[] = [];
  versionMapa: string = '';
  versionOsrodek: string = '';
  successCount = 0;
  errorCount = 0;
  totalCount = 0;
  waitingCount = 0;
  inProgressCount = 0;
  runningStage = 0;
  stageMessage = '';
  modalRef!: MDBModalRef;

  constructor(
    private softwareUpdatesStatusService: SoftwareUpdatesStatusService,
    private softwareUpdatesService: SoftwareUpdatesService,
    private modalService: MDBModalService,
  ) {}

  ngOnInit(): void {
    this.statusInfo = [];
    this.softwareUpdatesStatusService.startPolling(this.groupGuid);
    this.softwareUpdatesStatusService.getAllStatuses().subscribe((res) => {
      this.statusInfo = res;
      this.calculateProgress();
    });
  }

  onCloseClick() {
    this.softwareUpdatesStatusService.stopPolling();
    this.action.next(null);
  }

  ngOnDestroy(): void {
    this.softwareUpdatesStatusService.stopPolling();
  }

  calculateProgress() {
    this.successCount = this.statusInfo.filter((x) => x.stage === 2).length;
    this.errorCount = this.statusInfo.filter((x) => x.stage === 3).length;
    this.totalCount = this.statusInfo.length;
    this.waitingCount = this.statusInfo.filter((x) => x.stage === 0 || (x.stage === 1 && x.progress === 0)).length;
    this.inProgressCount = this.statusInfo.filter((x) => x.stage === 1 && x.progress > 0).length;
    this.runningStage =
      this.waitingCount === this.totalCount
        ? 0
        : this.successCount === this.totalCount
          ? 2
          : this.errorCount > 0 && this.successCount + this.errorCount === this.totalCount
            ? 3
            : 1;
    if (this.runningStage === 2) {
      this.softwareUpdatesStatusService.stopPolling();
    }
    switch (this.runningStage) {
      case 0:
        this.stageMessage = 'Oczekiwanie na start aktualizacji...';
        break;
      case 1:
        this.stageMessage = 'Aktualizacje w toku...';
        break;
      case 2:
        this.stageMessage = 'Aktualizacje zakończone sukcesem!';
        break;
      case 3:
        this.stageMessage = 'Aktualizacje zakończone z błędami!';
        break;
    }
  }

  tryAgain(updateId: number) {
    this.softwareUpdatesService.tryAgain(updateId).subscribe(() => {
      this.softwareUpdatesStatusService.startPolling(this.groupGuid);
    });
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }

  checkAppVersion(updateId: number) {
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-scrollable w-50 mx-auto',
      containerClass: '',
      animated: true,
      data: {
        updateId: updateId,
      },
    };
    this.modalRef = this.modalService.show(SoftwareUpdateVersionCheckComponent, modalOptions);
    this.modalRef.content.action.subscribe(() => {
      this.modalRef.hide();
    });
  }
}
