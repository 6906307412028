import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './modules/auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SharedModule } from './modules/shared/shared.module';
import { CasesModule } from './modules/cases/cases.module';
import { SettingsModule } from './modules/settings/settings.module';
import { LicencesModule } from './modules/licences/licences.module';
import { DocumentationModule } from './modules/documentation/documentation.module';
import { DashboardService } from './modules/dashboard/dashboard.service';
import { TrainingsModule } from './modules/trainings/trainings.module';
import { ClipboardModule } from 'ngx-clipboard';
import { AuthInterceptor } from './modules/auth/auth.interceptor';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import { registerLocaleData } from '@angular/common';
import { ServicesModule } from './modules/services/services.module';
import { UpdatesModule } from './modules/updates/updates.module';
import { MessagesModule } from './modules/messages/messages.module';
import { AdministrationModule } from './modules/administration/administration.module';
import { SoftwareUpdatesModule } from './modules/software-updates/software-updates.module';

registerLocaleData(localePl, 'pl-PL', localePlExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AdministrationModule,
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    CasesModule,
    DashboardModule,
    DocumentationModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    LicencesModule,
    MessagesModule,
    ClipboardModule,
    ReactiveFormsModule,
    ServicesModule,
    SettingsModule,
    SharedModule,
    SoftwareUpdatesModule,
    ToastModule.forRoot({
      preventDuplicates: true,
      opacity: 0.9,
      closeButton: true,
      tapToDismiss: true,
      progressBar: true,
      positionClass: 'md-toast-bottom-right',
    }),
    TrainingsModule,
    UpdatesModule,
  ],
  providers: [
    AuthService,
    DashboardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
