import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DynamicTable } from '../shared/models/table';
import { ContactItem } from './models/contact-item';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  private apiContactUrl = 'api/cp/contact';
  constructor(private http: HttpClient) {}

  getContacts(parameters: string = '', firstRun = false): Observable<DynamicTable> {
    if (parameters) {
      parameters = '?' + parameters;
    }
    return this.http
      .get(this.apiContactUrl + 's' + parameters + (firstRun ? '&firstRun=true' : ''))
      .pipe(map((res) => res as DynamicTable));
  }

  getContactById(id: number): Observable<ContactItem> {
    return this.http.get(this.apiContactUrl + `/${id}`).pipe(map((res) => res as ContactItem));
  }

  getContactByEmail(email: string): Observable<ContactItem> {
    return this.http
      .post(this.apiContactUrl + '/getByEmail', JSON.stringify(email))
      .pipe(map((res) => res as ContactItem));
  }

  addContact(contactItem) {
    return this.http.post(this.apiContactUrl + '/add', contactItem);
  }

  updateContact(contactItem) {
    return this.http.put(this.apiContactUrl + '/update', contactItem);
  }

  setPassword(passwordItem) {
    return this.http.put(this.apiContactUrl + '/setPassword', passwordItem);
  }

  resetPassword(id: number) {
    return this.http.post(this.apiContactUrl + '/resetPassword', id);
  }

  invite(id: number) {
    return this.http.post(this.apiContactUrl + '/invite', id);
  }
}
