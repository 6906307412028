import { AttachmentFile } from '../../../shared/models/file';
import { FilesService } from '../../files.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit {
  @Input() attachments: AttachmentFile[];
  constructor(private filesService: FilesService) {}

  ngOnInit() {}

  downloadFile(id: number) {
    this.filesService.downloadFile(id, 'commentAttachment');
  }
}
