import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateWithTime',
})
export class DateWithTimePipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.substring(0, 10) + ' ' + value.substring(11, 19) : '';
  }
}
