import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';
import { NgModule } from '@angular/core';
import { DocumentationListComponent } from './components/documentation-list/documentation-list.component';
import { DocumentationResolve } from './documentation-resolve-service';

const DOCUMENTATION: Route[] = [
  {
    path: 'dokumentacja',
    // component: <any>CustomersComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>DocumentationListComponent,
        resolve: {
          documentation: DocumentationResolve,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(DOCUMENTATION)],
  exports: [RouterModule],
})
export class DocumentationRoutingModule {}
