<div class="row mt-4">
  <div class="col-12 mt-3">
    <div class="card card-cascade narrower">
      <div class="view form-header py-2 mb-3 align-items-center">
        <h4 class="h4-responsive my-2">Rejestracja zgłoszenia</h4>
      </div>
      <div class="card-body">
        <form [formGroup]="applicationForm">
          <div class="row">
            <div class="col-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fFirstName"
                  formControlName="firstName"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                  data-error="Wypełnij pole"
                />
                <label class for="fFirstName">Imię</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fLastName"
                  formControlName="lastName"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                  data-error="Wypełnij pole"
                />
                <label class for="fLastName">Nazwisko</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fEmail"
                  formControlName="email"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                  data-error="Wypełnij pole"
                />
                <label class for="fEmail">Email</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fPhone"
                  formControlName="phone"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                  data-error="Wypełnij pole"
                />
                <label class for="fPhone">Telefon</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fNip"
                  formControlName="nip"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                  data-error="Wypełnij pole"
                />
                <label class for="fNip">Nip</label>
              </div>
            </div>
            <div class="col-8">
              <div class="md-form mb-2">
                <input
                  class="form-control form-control-sm"
                  id="fCustomerName"
                  formControlName="customerName"
                  mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="text"
                  data-error="Wypełnij pole"
                />
                <label class for="fCustomerName">Nazwa firmy / organizacji</label>
              </div>
            </div>
          </div>

          <div class="text-center mt-4">
            <button
              class="btn btn-default waves-light"
              (click)="onSubmit()"
              [disabled]="applicationForm.invalid"
              mdbRippleRadius
            >
              Wyślij
            </button>
            <button class="btn btn-outline-default waves-light" (click)="onCancel()" mdbRippleRadius>Anuluj</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
