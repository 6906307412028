import { Component, Input, OnInit } from '@angular/core';
import { DynamicTable } from '../../../shared/models/table';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { TableSettingsService } from '../../../shared/services/table-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdatesService } from '../../updates.service';
import { SoftwareUpdateManualDialogComponent } from '../modals/software-update-manual-dialog/software-update-manual-dialog.component';

@Component({
  selector: 'app-update-list',
  templateUrl: './update-list.component.html',
})
export class UpdateListComponent implements OnInit {
  updatesTable: DynamicTable;
  updateId = 0;
  updateViewStatus = 0;
  @Input() refCustomerId = 0;
  modalRef: MDBModalRef;

  constructor(
    private updatesService: UpdatesService,
    private tableSettingsService: TableSettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: MDBModalService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    const pageParam = 'paging.page=1&paging.recordsPerPage=10';
    this.updatesService.getInProgress(pageParam).subscribe((data) => {
      const result: any = data;
      this.updatesTable = result;
    });
  }

  reload() {
    const parameters = this.tableSettingsService.getRequestParams('SoftwareUpdatesClientList');
    this.updatesService.getInProgress(parameters).subscribe((data) => {
      this.updatesTable = data;
    });
  }

  return(reload) {
    if (reload) {
      this.reload();
    }
    this.updateViewStatus = 0;
  }

  clickDetails(id: number) {
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-full-height modal-top w-100 modal-dialog-scrollable',
      containerClass: '',
      animated: true,
      data: {
        licenceId: id,
      },
    };
  }
}
