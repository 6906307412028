<div *ngIf="showHeader" class="view form-header p-2 mb-0 mt-0 d-flex justify-content-between align-items-center">
  <div>
    <button
      (click)="setClickedAdd()"
      *ngIf="butAddVisible"
      class="btn btn-default waves-light btn-sm px-3"
      title="Dodaj"
      placement="right"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      (click)="emitReload(true)"
      class="btn btn-default waves-light btn-sm px-3"
      title="Odśwież"
      placement="right"
    >
      <i class="fa fa-sync"></i>
    </button>
    <button
      (click)="setClickedExport()"
      *ngIf="butExportVisible"
      class="btn btn-default waves-light btn-sm px-3"
      title="Eksport"
      placement="right"
    >
      <i class="fa fa-file-excel"></i>
    </button>
    <ng-content select="[externalButtons]"></ng-content>
  </div>

  <h5 class="font-bold">{{ tableCaption }}</h5>
  <div>
    <button
      (click)="columnsChoiceShow()"
      *ngIf="butColumnVisible"
      class="btn btn-default waves-light btn-sm px-3"
      title="Wybór kolumn"
      placement="left"
    >
      <i class="fa fa-columns mt-0"></i>
    </button>
    <button
      (click)="toggleFilter()"
      *ngIf="butFilterVisible"
      class="btn btn-default waves-light btn-sm px-3"
      title="Filtrowanie"
      placement="left"
    >
      <i class="fa fa-filter"></i>
    </button>
    <button
      (click)="clearFilter()"
      *ngIf="isFilter && butFilterVisible"
      class="btn btn-default waves-light btn-sm px-3"
      title="Wyczyść filtr"
      placement="left"
    >
      <i class="fa fa-eraser"></i>
    </button>
  </div>
</div>
<div class="table-responsive" style="min-height: 200px">
  <table mdbTable small="true" class="table-hover">
    <thead>
      <tr class="rgba-stylish-strong white-text small">
        <th *ngIf="multiSelect" style="width: 40px"></th>
        <th
          (click)="sortChange(cusHead.name)"
          *ngFor="let cusHead of dynamicTable.heads | showColumn"
          [ngStyle]="{ width: cusHead.width }"
          role="button"
          class="clickable"
          style="color: white"
          [ngClass]="{ 'text-right': cusHead.type === 6 }"
        >
          <span *ngIf="cusHead.sortingDirection > 0">
            <i
              [ngClass]="{
                'fa-sort-alpha-down': cusHead.sortingDirection == 1,
                'fa-sort-alpha-up': cusHead.sortingDirection == 2,
              }"
              class="fa"
            ></i>
          </span>
          {{ cusHead.title }}
        </th>
      </tr>
      <tr
        *ngIf="showFilter && filterVisible"
        app-table-filter-row
        (reload)="emitReload($event)"
        [code]="dynamicTable.code"
        [tableHeaders]="headers"
        [multiSelect]="multiSelect"
      ></tr>
    </thead>
    <tbody>
      <tr
        (click)="setClickedRow(cusRow.attributes[0], i)"
        (dblclick)="setDoubleClickedRow(cusRow.attributes[0], i)"
        *ngFor="let cusRow of dynamicTable.rows; let i = index"
        [ngClass]="{ 'rgba-stylish-slight': i == selectedRow }"
        class="small clickable"
      >
        <td *ngIf="multiSelect">
          <div class="form-check mr-0">
            <mdb-checkbox (click)="clickCheckboxRow($event, cusRow.attributes[0], i)"> </mdb-checkbox>
          </div>
        </td>
        <td
          *ngFor="let cusHead of dynamicTable.heads | showColumn; let j = index"
          [ngClass]="{ 'text-right': cusHead.type === 6 }"
        >
          <span *ngIf="cusHead.type != 9; else checkboxField">{{ cusRow.attributes[cusHead.id] }}</span>
          <ng-template #checkboxField>
            <div class="form-check mr-0">
              <mdb-checkbox [checked]="cusRow.attributes[cusHead.id]" [disabled]="true"> </mdb-checkbox>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="showFooter" class="view p-2 mb-0 mt-0 d-flex justify-content-between align-items-center grey lighten-4">
  <app-table-paging
    (goToPage)="pageChange()"
    [paging]="dynamicTable.paging"
    [showPageChoose]="showPageChoose"
    [tableCode]="dynamicTable.code"
  ></app-table-paging>
</div>

<div
  #columnsModal="mdb-modal"
  aria-hidden="true"
  aria-labelledby="filtersModalLabel"
  class="modal fade right"
  id="fullHeightModalRight"
  mdbModal
  role="dialog"
  tabindex="-1"
>
  <div class="modal-dialog modal-full-height modal-right" role="document">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h5 class="modal-title w-100" id="filtersModalLabel">Konfiguracja widoku</h5>
        <button
          (click)="columnsModal.hide()"
          aria-label="Close"
          class="close white-text"
          data-dismiss="modal"
          type="button"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="isSelectingView; else addView" class="row mb-3 mt-3">
          <div class="col-12">
            <mdb-select
              [(ngModel)]="selectedView"
              [ngModelOptions]="{ standalone: true }"
              [options]="viewSelect"
              placeholder="Wybierz"
            ></mdb-select>
            <label>Wybrany widok</label>
          </div>
        </div>

        <ng-template #addView>
          <form [formGroup]="viewForm" class="mt-3">
            <div class="row">
              <div class="col-12">
                <div class="md-form">
                  <input
                    [validateSuccess]="false"
                    class="form-control form-control-sm"
                    data-error="Pole wymagane"
                    formControlName="viewName"
                    id="fviewName"
                    mdbInput
                    mdbValidate
                    type="text"
                  />
                  <label class for="fviewName">Nazwa widoku</label>
                </div>
              </div>
            </div>
            <h6>Wybrane kolumny</h6>
            <div class="list-group columnsList">
              <div *ngFor="let cusHead of viewSetting.columns" class="list-group-item">
                <mdb-checkbox (change)="updateCheckedOptions(cusHead, $event)" [checked]="cusHead.show">{{
                  cusHead.title
                }}</mdb-checkbox>
              </div>
            </div>
          </form>
        </ng-template>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          (click)="saveView()"
          *ngIf="!isSelectingView"
          [disabled]="viewForm.invalid"
          class="btn btn-default ml-auto"
          mdbWavesEffect
        >
          Zapisz
        </button>
        <button
          (click)="toggleAddViewForm(true)"
          *ngIf="!isSelectingView"
          class="btn btn-outline-warning ml-auto"
          mdbWavesEffect
        >
          Anuluj
        </button>
        <button (click)="setView()" *ngIf="isSelectingView" class="btn btn-default ml-auto" mdbWavesEffect>
          Wybierz
        </button>
        <button
          (click)="toggleAddViewForm(false)"
          *ngIf="isSelectingView"
          class="btn btn-default waves-light"
          mdbRippleRadius
        >
          Dodaj
        </button>
        <button
          (click)="columnsModal.hide()"
          class="btn btn-outline-default waves-light"
          data-dismiss="modal"
          mdbWavesEffect
        >
          Zamknij
        </button>
      </div>
    </div>
  </div>
</div>
