import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { SoftwareUpdateManualDef } from '../../models/software-update-manual-def';
import { SoftwareUpdatesService } from '../../software-updates.service';
import { ToolsService } from '../../../shared/services/tools.service';

@Component({
  selector: 'app-software-update-manual-dialog',
  templateUrl: './software-update-manual-dialog.component.html',
})
export class SoftwareUpdateManualDialogComponent implements OnInit {
  definitionForm!: UntypedFormGroup;
  action: Subject<any> = new Subject();
  softwareUpdateManualDef!: SoftwareUpdateManualDef;
  isDataLoaded = false;

  constructor(
    private softwareUpdatesService: SoftwareUpdatesService,
    private toolsService: ToolsService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    forkJoin([this.softwareUpdatesService.getForManualUpdate()]).subscribe((results) => {
      this.softwareUpdateManualDef = results[0];
      this.softwareUpdateManualDef.updateDefs.forEach(
        (item) => (item.active = !item.isCurrentVersion && item.subscriptionActive),
      );
      this.definitionForm = this.buildConfirmForm();
      this.isDataLoaded = true;
    });
  }

  buildConfirmForm() {
    return this.formBuilder.group({
      architecture: [{ value: this.softwareUpdateManualDef.architecture, disabled: true }, Validators.required],
      date: [this.softwareUpdateManualDef.date, Validators.required],
      time: [this.softwareUpdateManualDef.time, Validators.required],
      parallelLimit: [{ value: 2, disabled: true }, Validators.required],
    });
  }

  onYesClick(watch: boolean) {
    if (this.definitionForm.valid) {
      this.softwareUpdateManualDef.architecture = this.definitionForm.controls['architecture'].value;
      this.softwareUpdateManualDef.date = this.definitionForm.controls['date'].value;
      this.softwareUpdateManualDef.time = this.definitionForm.controls['time'].value;
      this.softwareUpdateManualDef.parallelLimit = this.definitionForm.controls['parallelLimit'].value;
      this.action.next({ status: true, definitionItem: this.softwareUpdateManualDef, watch });
    } else {
      this.toolsService.validateAllFormFields(this.definitionForm);
    }
  }

  onNoClick() {
    this.action.next({ status: false });
  }

  onChangeSelectAll(event: any) {
    this.softwareUpdateManualDef.updateDefs
      .filter((item) => item.subscriptionActive)
      .forEach((item) => (item.active = event.checked));
  }
}
