import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: number): string {
    const unit = 1024;
    if (value < unit) {
      return `${value} B`;
    } else {
      const exp = Math.floor(Math.log(value) / Math.log(unit));
      const pre = 'kMGTPE'.charAt(exp - 1);
      return `${(value / Math.pow(unit, exp)).toFixed(1).replace('.', ',')} ${pre}B`;
    }
  }
}
