<div class="modal-content">
  <div class="modal-header light-blue darken-3 white-text">
    <h4 class="modal-title w-100" id="myModalLabel">{{ 'Aktualizacja aplikacji' }}</h4>
  </div>
  <div *ngIf="isDataLoaded" class="modal-body">
    <h5>{{ 'Wybierz aplikacje do zaktualizowania' }}</h5>
    <form [formGroup]="definitionForm">
      <div class="row">
        <div class="col">
          <div class="md-form">
            <mdb-date-picker
              [locale]="'pl'"
              formControlName="date"
              required
              mdbValidate
              [validateSuccess]="false"
              label="Data"
              buttonLabel="Ustaw"
              buttonClearLabel="Wyczyść"
            ></mdb-date-picker>
          </div>
        </div>
        <div class="col">
          <div class="md-form">
            <mdb-time-picker
              formControlName="time"
              required
              mdbValidate
              [validateSuccess]="false"
              label="Godzina"
              buttonLabel="Ustaw"
              buttonClearLabel="Wyczyść"
            >
            </mdb-time-picker>
          </div>
        </div>
        <div class="col">
          <div class="md-form">
            <label class="active text-black" for="fParallelLimit">Limit równoległej instalacji</label>
            <input
              class="form-control form-control-sm"
              formControlName="parallelLimit"
              id="fParallelLimit"
              mdbInput
              mdbValidate
              type="text"
              [validateSuccess]="false"
              data-error="Wypełnij pole"
            />
          </div>
        </div>
        <div class="col">
          <div class="md-form">
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="architecture86"
                value="x86"
                formControlName="architecture"
                name="architecture"
              />
              <label class="form-check-label" for="architecture86">x86</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="architecture64"
                value="x64"
                formControlName="architecture"
                name="architecture"
              />
              <label class="form-check-label" for="architecture64">x64</label>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="md-form">
            <label class="active text-black" for="fMapa">Wersja Mapy</label>
            <div id="fMapa" class="text-muted pt-3">
              <span>{{ softwareUpdateManualDef.versionMapa }}</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="md-form">
            <label class="active text-black" for="fOsrodek">Wersja Ośrodka</label>
            <div id="fOsrodek" class="text-muted pt-3">
              <span>{{ softwareUpdateManualDef.versionOsrodek }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="softwareUpdateManualDef && softwareUpdateManualDef.updateDefs.length > 0" class="row mt-1">
      <div class="col-12 table-responsive" style="overflow-y: scroll">
        <table mdbTable small="true">
          <thead>
            <tr>
              <th>
                <mdb-checkbox (change)="onChangeSelectAll($event)"></mdb-checkbox>
              </th>
              <th><span class="mx-2 my-2">Nazwa</span></th>
              <th><span class="mx-2 my-2">Aplikacja</span></th>
              <th><span class="mx-2 my-2">Wersja</span></th>
              <th><span class="mx-2 my-2">Aktualna</span></th>
              <th><span class="mx-2 my-2">Status</span></th>
            </tr>
          </thead>
          <tbody style="max-height: 200px; overflow-y: scroll">
            <tr
              *ngFor="let software of softwareUpdateManualDef.updateDefs"
              [ngClass]="{ 'text-danger': !software.subscriptionActive }"
            >
              <td>
                <mdb-checkbox [(ngModel)]="software.active" [disabled]="!software.subscriptionActive"></mdb-checkbox>
              </td>
              <td>{{ software.software }}</td>
              <td>{{ software.application }}</td>
              <td>{{ software.version }}</td>
              <td>
                <mdb-checkbox [checked]="software.isCurrentVersion" [disabled]="true"></mdb-checkbox>
              </td>
              <td>
                {{
                  software.subscriptionActive
                    ? !software.isCurrentVersion
                      ? 'Aktualizacja'
                      : software.active
                        ? 'Odświeżenie'
                        : ''
                    : 'Subskrypcja wygasła'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-default waves-light" (click)="onYesClick(true)">Zapisz i obserwuj</button>
    <button class="btn btn-default waves-light" (click)="onYesClick(false)">Zapisz i zamknij</button>
    <button class="btn btn-outline-default" data-dismiss="modal" (click)="onNoClick()">Anuluj</button>
  </div>
</div>
