import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SoftwareVersionInfoItem } from '../../models/software-version-info';
import { SoftwareUpdatesService } from '../../software-updates.service';

@Component({
  selector: 'app-software-update-version-check-dialog',
  templateUrl: './software-update-version-check-dialog.component.html',
})
export class SoftwareUpdateVersionCheckDialogComponent implements OnInit {
  updateId: number = 0;
  action: Subject<any> = new Subject();
  versionInfo!: SoftwareVersionInfoItem;
  isChecking = true;

  constructor(private softwareUpdatesService: SoftwareUpdatesService) {}

  ngOnInit(): void {
    this.checkVersion();
    // this.isChecking = false;
  }

  private checkVersion() {
    this.isChecking = true;
    this.softwareUpdatesService.checkAppVersion(this.updateId, true).subscribe((res) => {
      this.versionInfo = res;
      this.isChecking = false;
    });
  }

  closeClick() {
    this.action.next({ status: false });
  }

  redoClick() {
    this.checkVersion();
  }
}
