import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';
import { NgModule } from '@angular/core';
import { FullViewComponent } from './components/full-view/full-view.component';

const UPDATES: Route[] = [
  {
    path: 'aktualizacje',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>FullViewComponent,
        // resolve: {
        //   updates: UpdatesResolve,
        // }
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(UPDATES)],
  exports: [RouterModule],
})
export class UpdatesRoutingModule {}
