<div class="row">
  <div class="col-12">
    <app-table-main
      *ngIf="serviceTable"
      tableCaption="Zamówione usługi serwisowe"
      (reload)="servicesReload()"
      [dynamicTable]="serviceTable"
    >
    </app-table-main>
  </div>
</div>
