import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListItem } from '../models/list-item';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { SoftwareMainVersionItem } from '../../updates/models/software-main-version-item';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
  ) {}

  caseCriticalities() {
    return this.cacheService.get('caseCriticality', this.getListByCode('CaseCriticality'));
  }

  caseSources() {
    return this.cacheService.get('caseSource', this.getListByCode('CaseSource'));
  }

  caseStatuses() {
    return this.cacheService.get('caseStatus', this.getListByCode('CaseStatus'));
  }

  caseTypes() {
    return this.cacheService.get('caseType', this.getListByCode('CaseType'));
  }

  programCP() {
    return this.cacheService.get('program', this.getProgramCP());
  }

  serviceWorkers() {
    return this.cacheService.get('serviceWorkers', this.getServiceWorker());
  }

  getProgramGroup(): Observable<ListItem[]> {
    return this.http.get('api/attributeList/programGroup').pipe(map((res) => (res as ListItem[]) || []));
  }

  getProgramCP(): Observable<ListItem[]> {
    return this.http.get('api/attributeList/programCP').pipe(map((res) => (res as ListItem[]) || []));
  }

  getListByCode(code: string): Observable<ListItem[]> {
    return this.http.get('api/attributeList/' + code).pipe(map((res) => (res as ListItem[]) || []));
  }

  getServiceWorker(): Observable<ListItem[]> {
    return this.http.get('api/attributeList/serviceWorkers').pipe(map((res) => (res as ListItem[]) || []));
  }

  getSkins(): Observable<ListItem[]> {
    return this.http.get('api/attributeList/skins').pipe(map((res) => (res as ListItem[]) || []));
  }
}
