import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor() {}

  downloadFile(id, type) {
    window.open('api/file/' + id + '/' + type);
  }

  downloadMailAttachment(mailingId, fileId) {
    window.open(`api/cp/file/${mailingId}/mailingAttachment/${fileId}`);
  }

  downloadDocumentation(id: number, type: string, filesData: string[] = null) {
    const endpoint = '/api/file/' + id.toString() + '/documentation';
    switch (type) {
      case 'all':
        window.open(endpoint + '?type=all', '_blank');
        break;
      case 'zip':
        break;
      case 'single':
        filesData.forEach((item) => {
          window.open(endpoint + '?type=single&list=' + item, '_blank');
        });
        break;
    }
  }

  downloadUpdate(id) {
    window.open('api/cp/file/' + id + '/customerUpdate');
  }
}
